import { Injectable } from "@angular/core";
import { TRoiSelectionType } from "@telespot/sdk";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, shareReplay, tap } from "rxjs/operators";

import { TOsdActiveAction } from "../../models/osd-active-action";
import { Store } from "@ngrx/store";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { preSyncAnalysis } from "@telespot/analysis-refactor/data-access";

@Injectable({
  providedIn: "root",
})
export class ViewerService {
  constructor(private _store: Store) {}
  private _currentViewerMode: TOsdActiveAction;
  public get currentViewerMode() {
    return this._currentViewerMode;
  }
  private _activeViewerMode$ = new BehaviorSubject<TOsdActiveAction>(
    TOsdActiveAction.drawing
  );
  public readonly activeViewerMode$ = this._activeViewerMode$
    .asObservable()
    .pipe(
      tap((mode) => (this._currentViewerMode = mode)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  private _activeDrawingMode$ = new BehaviorSubject<TRoiSelectionType>(
    TRoiSelectionType.center
  );
  public readonly activeDrawingMode$ = this._activeDrawingMode$
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay(1));

  private _page$ = new BehaviorSubject<number>(0);
  public readonly page$ = this._page$
    .asObservable()
    .pipe(distinctUntilChanged());

  public toggleDrawingMode(mode: TRoiSelectionType) {
    this._activeDrawingMode$.next(mode);
  }

  public toggleViewerMode(mode: TOsdActiveAction) {
    this._activeViewerMode$.next(mode);
  }

  public setPage(index: number) {
    this._page$.next(index);
  }

  public save() {
    this._store.dispatch(preSyncAnalysis());
  }
}
