import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { environment } from "@telespot/shared/environment";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

export interface RoiItem {
  id: string;
  sampleId: string;
  assetId: string;
  analysisId: string;
  findingId: string;
  findingVersionId: string;
  creatorEntity: string;
  creatorId: string;
  stepId: string;
  pipelineId: string;
  filename: string;
  findingVersion: number;
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  //Review
  labels: { [key: string]: number };
  score: number;
}
interface RoisResponse {
  items: RoiItem[];
  nextToken?: string;
}
@Injectable({
  providedIn: "root",
})
export class MosaicService {
  constructor(
    private _http: HttpClient,
    private _logger: LoggerService,
    private sanitizer: DomSanitizer
  ) {}

  async getRoisFromSample(
    subjectId: string,
    creatorId: string,
    limit: number = 100,
    label: string,
    pipelines?: string[],
    nextToken?: string
  ) {
    try {
      const endpoint = `mosaics/samples/${subjectId}/rois`;
      let baseUrl = `${environment.api.url}/${endpoint}?creator=${creatorId}&groupBy=id&limit=${limit}`;

      if (pipelines.length !== 0) {
        pipelines.map((p) => {
          baseUrl = baseUrl + `&pipelines=${p}`;
        });
      }

      if (label) {
        baseUrl = baseUrl + `&label=${label}`;
      }

      const url = nextToken ? baseUrl + `&nextToken=${nextToken}` : baseUrl;

      return this._http.get<RoisResponse>(url).toPromise();
    } catch (err) {
      this._logger.error("[Mosaic service] - Error rois", err.message);
      throw err;
    }
  }

  async getRoisFromFinding(
    subjectId: string,
    limit: number = 100,
    nextToken?: string
  ) {
    try {
      const endpoint = `mosaics/findings/${subjectId}/rois`;
      const baseUrl = `${environment.api.url}/${endpoint}?groupBy=id&limit=${limit}`;

      const url = nextToken ? baseUrl + `&nextToken=${nextToken}` : baseUrl;

      return this._http.get<RoisResponse>(url).toPromise();
    } catch (err) {
      this._logger.error("[Mosaic service] - Error rois", err.message);
      throw err;
    }
  }

  getCrop(filename: string): Observable<Blob> {
    const endpoint = "mosaics/crops";
    const url = `${environment.api.url}/${endpoint}/${filename}`;
    const headers = new HttpHeaders({ Accept: "image/jpeg, */*" });
    return this._http.get(url, { headers: headers, responseType: "blob" });
  }

  getCropURL(filename: string): Observable<SafeUrl> {
    return this.getCrop(filename).pipe(
      map((blob) =>
        this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
      ),
      catchError((err) => {
        console.error(err);
        return of(this.sanitizer.bypassSecurityTrustUrl("about:blank"));
      })
    );
  }

  getCropSanitizedUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
