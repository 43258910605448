import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AnalysisState, CloudFunctions } from "@telespot/sdk";
import {
  map,
  switchMap,
  catchError,
  mergeMap,
  withLatestFrom,
  filter,
  take,
} from "rxjs/operators";
import { defer, EMPTY, from, of } from "rxjs";
import { SampleAnalysisService } from "../services/sample-analysis/sample-analysis.service";
import * as ViewerCtxActions from "./viewer-context.actions";
import {
  analysisState,
  assetsProcessedAndFiltered,
  numAssets,
  refStripItemsNotFetched,
  selectAsset,
  selectAssetIndex,
  selectRefStripElements,
} from "./viewer-context.selectors";
import { Store } from "@ngrx/store";
import {
  analysisActionError,
  analysisCopied,
  analysisSynced,
  assetIdsFromAnalysis,
  createAnalysis,
  createAnalysisFromROIs,
  getActiveSegmentationFinding,
  historyActionError,
  Mode,
  protocolActionError,
  segmAnalysisCreated,
  selectHasSegmentationTasks,
  selectMode,
  selectROILabelCountFromActiveROIs,
  updateStats,
} from "../state";
import { AnalysisReviewService } from "../services/analysis-review/analysis-review.service";
import { CaseAnalysisService } from "../services/case-analysis/case-analysis.service";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  MaskViewerService,
  ViewerConfigService,
} from "@telespot/shared/viewers/data-access";
import { LargeCells } from "@telespot/domain";

@Injectable()
export class ViewerCtxEffects {
  constructor(
    private actions$: Actions,
    private _sampleAnalysisService: SampleAnalysisService,
    private store$: Store,
    private _analysisReviewService: AnalysisReviewService,
    private _caseAnalysisService: CaseAnalysisService,
    private _maskViewerService: MaskViewerService,
    private _viewerConfigService: ViewerConfigService
  ) {}

  fetchAnalysisState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.updateSampleAnalysisState),
      withLatestFrom(
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(getActiveSegmentationFinding),
        this.store$.select(analysisState)
      ),
      switchMap(
        ([
          { historyId, sample },
          hasSegmentationTasks,
          activeFinding,
          currAnalysisState,
        ]) => {
          return this._sampleAnalysisService
            .getAnalysisState(sample, historyId)
            .pipe(
              switchMap((analysisState) =>
                of({ _analysisState: analysisState, sampleId: sample })
              ),
              map(({ _analysisState, sampleId }) => {
                if (_analysisState) {
                  this._maskViewerService.saveMaskOnStorageIfNeeded(
                    hasSegmentationTasks,
                    activeFinding,
                    this._sampleAnalysisService.isAuthUser(
                      currAnalysisState?.user?.toPointer() ?? undefined
                    )
                  );
                  return ViewerCtxActions.sampleAnalysisStateFetched({
                    analysisState: _analysisState,
                  });
                }
                return ViewerCtxActions.createAnalysisState({ sampleId });
              }),
              catchError((error) =>
                of(
                  ViewerCtxActions.roisActionError({
                    error: `[updateSampleAnalysisState]: ${error.message}`,
                  })
                )
              )
            );
        }
      )
    )
  );

  createAnalysisState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.createAnalysisState),
      switchMap(({ sampleId }) => {
        return this._sampleAnalysisService.resolveAnalysisState(sampleId).pipe(
          map((_analysisState) => {
            return ViewerCtxActions.sampleAnalysisStateFetched({
              analysisState: _analysisState as AnalysisState,
            });
          }),
          catchError((error) =>
            of(
              ViewerCtxActions.roisActionError({
                error: `[createAnalysisState]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  checkCaseAnalysisState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.checkCaseAnalysisState),
      switchMap(({ caseId }) => {
        return this._caseAnalysisService.checkUserAnalysisState(caseId).pipe(
          map((analysisState) => {
            if (analysisState) {
              return ViewerCtxActions.caseAnalysisStateFetched({
                analysisState: analysisState as AnalysisState,
              });
            }
            return ViewerCtxActions.createCaseAnalysisState({ caseId });
          }),
          catchError((error) =>
            of(
              ViewerCtxActions.roisActionError({
                error: `[checkCaseAnalysisState]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  createCaseAnalysisState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.createCaseAnalysisState),
      switchMap(({ caseId }) => {
        return this._caseAnalysisService.createCaseAnalysisState(caseId).pipe(
          map((analysisState) => {
            return ViewerCtxActions.caseAnalysisStateFetched({
              analysisState: analysisState as AnalysisState,
            });
          }),
          catchError((error) =>
            of(
              ViewerCtxActions.roisActionError({
                error: `[createCaseAnalysisState]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateRefStripData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.requestStripData),
      mergeMap(({ requestRefStripData }) =>
        of(requestRefStripData).pipe(
          withLatestFrom(
            this.store$.select(
              assetIdsFromAnalysis(requestRefStripData.createdBy)
            ),
            this.store$.select(
              refStripItemsNotFetched(requestRefStripData.limits)
            )
          ),
          map(([req, assetIds, itemsToFetch]) =>
            this._sampleAnalysisService.isAuthUser(
              requestRefStripData.createdBy
            )
              ? { req, assetIds, isAuthUser: true, itemsToFetch }
              : { req, assetIds: [], isAuthUser: false, itemsToFetch }
          )
        )
      ),
      mergeMap(({ req, assetIds, isAuthUser, itemsToFetch }) => {
        if (
          req.createdBy === undefined ||
          req.sampleId === undefined ||
          itemsToFetch.length === 0
        )
          return EMPTY;
        return defer(() =>
          CloudFunctions.GetRefStripData({
            sampleId: req.sampleId,
            createdBy: req.createdBy,
            itemsToFetch,
          })
        ).pipe(
          switchMap((refStripDataResponse) =>
            of({ itemsRequested: itemsToFetch, refStripDataResponse, assetIds })
          ),
          map(({ itemsRequested, refStripDataResponse, assetIds }) =>
            ViewerCtxActions.refStripLoaded({
              refStripDataResponse,
              itemsRequested,
              assetIds,
              isAuthUser,
            })
          ),
          catchError((error) => {
            return of(
              ViewerCtxActions.roisActionError({
                error: `[requestStripData]: ${error.message}`,
              })
            );
          })
        );
      })
    )
  );

  loadAssetIndex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.loadAssetIndex),
      withLatestFrom(
        this.store$.select(selectAssetIndex),
        this.store$.select(numAssets),
        this.store$.select(assetsProcessedAndFiltered),
        this.store$.select(selectRefStripElements)
      ),
      mergeMap(
        ([
          { index, step },
          prevIndex,
          numAssets,
          assetsFiltered,
          refStripItems,
        ]) => {
          let newAssetIndex;

          if (step) {
            const itemIndex = assetsFiltered.findIndex(
              (a) => a.assetIndex === index
            );
            newAssetIndex =
              assetsFiltered.find((item, index) => index === itemIndex + step)
                ?.assetIndex ?? index;
          } else {
            if (refStripItems.length > 0) {
              const unprocessed = assetsFiltered.find(
                (a) => a.assetIndex === index
              );
              if (!unprocessed) return EMPTY;
            }

            newAssetIndex = index;
          }

          return this._sampleAnalysisService
            .updateAssetIndex(newAssetIndex)
            .pipe(
              map((newAssetIndex) =>
                ViewerCtxActions.assetIndexLoaded({ index: newAssetIndex })
              ),
              catchError((error) => {
                return of(
                  ViewerCtxActions.roisActionError({
                    error: `[loadAssetIndex]: ${error.message}`,
                  })
                );
              })
            );
        }
      )
    )
  );

  availableAnalysts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.setSelectedSample),
      mergeMap(({ selectedSample }) => {
        return this._analysisReviewService
          .getAnalysisStatesFromSample(selectedSample)
          .pipe(
            map(({ analysisStates, currentUserId }) => {
              return ViewerCtxActions.setAvailableAnalysts({
                analysisStates,
                currentUserId,
              });
            }),
            catchError((error) => {
              return of(
                ViewerCtxActions.roisActionError({
                  error: `[setSelectedSample]: ${error.message}`,
                })
              );
            })
          );
      })
    )
  );

  markAsReviewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(analysisSynced),
      withLatestFrom(this.store$.select(selectMode)),
      filter(([action, mode]) => mode === Mode.REVIEW),
      map(([action]) => action),
      mergeMap(({ idChanges }) => {
        const assetIds = Array.from(
          new Set(
            idChanges
              .filter(
                (change) =>
                  change.previous.startsWith("copy:") && change?.assetId
              )
              .map((change) => change?.assetId)
          )
        );
        return of(assetIds).pipe(
          map((assetIds) => {
            return ViewerCtxActions.markAssetAsReviewed({ assetIds });
          }),
          catchError((error) => {
            return of(
              ViewerCtxActions.roisActionError({
                error: `[analysisSynced]: ${error.message}`,
              })
            );
          })
        );
      })
    )
  );

  markAsAnalyzed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        createAnalysisFromROIs,
        createAnalysis,
        segmAnalysisCreated,
        analysisCopied
      ),
      withLatestFrom(this.store$.select(selectAsset)),
      mergeMap(([action, currAsset]) => {
        return of(
          ViewerCtxActions.markAssetAsAnalyzed({ assetId: currAsset.id })
        );
      })
    )
  );

  extractAssetInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ViewerCtxActions.extractAssetsInfo,
        ViewerCtxActions.fetchAssetInfo
      ),
      withLatestFrom(this.store$.select(selectRefStripElements)),
      mergeMap(([action, refstripItems]) => {
        if (action.skip === 0) {
          return from(
            this._sampleAnalysisService.setNumAssets(action.selectedSample)
          ).pipe(
            map((numAssets) => ({
              skip: action.skip,
              sample: action.selectedSample,
              refstripItems,
            }))
          );
        }
        return of({
          sample: action.selectedSample,
          skip: action.skip,
          refstripItems,
        });
      }),
      mergeMap(({ sample, skip, refstripItems }) => {
        return from(
          this._sampleAnalysisService.fetchSampleAssets(sample, skip)
        ).pipe(
          map((result) => ({
            sampleAssets: result.sampleAssets,
            skip: result.skip,
            refstripItems,
          }))
        );
      }),
      mergeMap(({ sampleAssets, skip, refstripItems }) => {
        const refStripItemsMapped =
          this._sampleAnalysisService.mapRefStripItems(sampleAssets, skip);

        if (refstripItems.length === 0) {
          return [
            ViewerCtxActions.loadRefStripElements({
              refStripItems: refStripItemsMapped,
            }),
            ViewerCtxActions.loadAssetIndex({ index: 0 }),
          ];
        }

        return [
          ViewerCtxActions.loadRefStripElements({
            refStripItems: refStripItemsMapped,
          }),
        ];
      }),
      catchError((error) =>
        of(
          ViewerCtxActions.roisActionError({
            error: `[extractAssetsInfo, fetchAssetInfo]: ${error.message}`,
          })
        )
      )
    )
  );

  getSampleReferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.setSelectedSample),
      mergeMap(({ selectedSample }) => {
        return this._sampleAnalysisService.fetchSampleAndCaseRef(
          selectedSample
        );
      }),
      mergeMap(({ nextCase, previousCase, nextSample, previousSample }) => {
        return this._sampleAnalysisService
          .fetchSampleRefFromCases(
            nextCase,
            previousCase,
            nextSample,
            previousSample
          )
          .pipe(
            map((references) => {
              return ViewerCtxActions.loadCaseSampleRef({ ...references });
            }),
            catchError((error) => {
              return of(
                ViewerCtxActions.roisActionError({
                  error: `[setSelectedSample (getSampleReferences)]: ${error.message}`,
                })
              );
            })
          );
      })
    )
  );

  fetchStateErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        analysisActionError,
        protocolActionError,
        historyActionError,
        ViewerCtxActions.roisActionError
      ),
      mergeMap(({ error }) => {
        if (error) this._sampleAnalysisService.giveUserFeedback(error);
        return of(ViewerCtxActions.noOpAction());
      })
    )
  );

  changeAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.setAsset),
      withLatestFrom(
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(getActiveSegmentationFinding),
        this.store$.select(analysisState)
      ),
      mergeMap(
        ([action, hasSegmentationTasks, activeFinding, currAnalysisState]) => {
          this._maskViewerService.saveMaskOnStorageIfNeeded(
            hasSegmentationTasks,
            activeFinding,
            this._sampleAnalysisService.isAuthUser(
              currAnalysisState?.user?.toPointer() ?? undefined
            )
          );
          return of(ViewerCtxActions.assetLoaded({ asset: action.asset }));
        }
      )
    )
  );

  // REVIEW: ad-hoc for big cells filter
  checkLargeCell$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStats),
      withLatestFrom(this.store$.select(selectAsset)),
      mergeMap(([{ optionsCount }, currAsset]) => {
        const labelsUUIDS = Object.keys(optionsCount);

        if (!labelsUUIDS.length) {
          return EMPTY;
        }

        const uuid = this._sampleAnalysisService.getLabelUUID(
          LargeCells.MEGACARIOCITO
        );

        if (!uuid) {
          return EMPTY;
        }

        return from(uuid).pipe(
          switchMap((uuid) => {
            if (!labelsUUIDS.some((labelUUID) => labelUUID === uuid)) {
              return EMPTY;
            }

            if (optionsCount[uuid] > 0) {
              return of(
                ViewerCtxActions.markAssetAsLargeCells({
                  assetId: currAsset.id,
                  markAs: true,
                })
              );
            }

            return this.store$
              .select(selectROILabelCountFromActiveROIs(uuid))
              .pipe(
                take(1),
                switchMap((count) => {
                  if (count === 0) {
                    return of(
                      ViewerCtxActions.markAssetAsLargeCells({
                        assetId: currAsset.id,
                        markAs: false,
                      })
                    );
                  }
                  return EMPTY;
                }),
                catchError((error) => {
                  return of(ViewerCtxActions.roisActionError(error));
                })
              );
          }),
          catchError((error) => {
            return of(ViewerCtxActions.roisActionError(error));
          })
        );
      })
    )
  );
}
