import { createAction, props } from "@ngrx/store";
import {
  AnalysisState,
  Asset,
  Case,
  IAssetROIWithModels,
  Sample,
} from "@telespot/sdk";
import { ISampleItem } from "../models/i-sample-item";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ViewerConfig } from "@telespot/shared/viewers/data-access";

export interface requestAnalysis {
  assetId: string;
  createdBy: Parse.Pointer;
  sampleId: string;
  sampleAnalysis: boolean;
}

export interface requestRefStripData {
  createdBy: Parse.Pointer;
  sampleId: string;
  limits: Limits;
}

export interface Limits {
  end: number;
  start: number;
}

export interface refStripDataResponse {
  assetId: string;
  reviewed: boolean;
  fav: boolean;
  analyzed: boolean;
  favAnalysis: boolean;
}

export enum AnalysisMode {
  MOSAIC = "mosaic",
  NORMAL = "normal",
}

export const clearViewerCtxState = createAction(
  "[VIEWER_CONTEXT] Clear viewer context state"
);
export const setSelectedSample = createAction(
  "[VIEWER_CONTEXT] Set sample",
  props<{ selectedSample: Sample }>()
);
export const extractAssetsInfo = createAction(
  "[VIEWER_CONTEXT] Extract sample assets info",
  props<{ selectedSample: Sample; skip: number }>()
);
export const fetchAssetInfo = createAction(
  "[VIEWER_CONTEXT] Extract sample assets info",
  props<{ selectedSample: Sample; skip: number }>()
);
export const setNumAssets = createAction(
  "[VIEWER_CONTEXT] Set number of assets from sample",
  props<{ numAssets: number }>()
);
export const setAsset = createAction(
  "[VIEWER_CONTEXT] Change asset",
  props<{ asset: Asset }>()
);

export const assetLoaded = createAction(
  "[VIEWER_CONTEXT] Asset loaded",
  props<{ asset: Asset }>()
);

export const setViewerType = createAction(
  "[VIEWER_CONTEXT] Change Viewer Type",
  props<{ viewerType: string }>()
);

export const toogleAnalysisMode = createAction(
  "[VIEWER_CONTEXT] Toogle AnalysisMode",
  props<{ mode: string }>()
);

export const toogleMosaicView = createAction(
  "[VIEWER_CONTEXT] Toogle MosaicView",
  props<{ mosaicView: string; selectedLabel?: string }>()
);

//review old function
export const acceptAIROIs = createAction(
  "[ROIS] Accept AI ROIs",
  props<{ rois: IAssetROIWithModels[] }>()
);

export const updateSampleAnalysisState = createAction(
  "[VIEWER_CONTEXT] Update Analysis State",
  props<{ historyId: string; sample: string }>()
);
export const sampleAnalysisStateFetched = createAction(
  "[VIEWER_CONTEXT] Analysis State succesfully fetched",
  props<{ analysisState: AnalysisState }>()
);

export const bookmarkAsset = createAction(
  "[VIEWER_CONTEXT] Bookmark current asset",
  props<{ assetId: string; analysisState: AnalysisState }>()
);

export const createAnalysisState = createAction(
  "[VIEWER_CONTEXT] Create Analysis State",
  props<{ sampleId: string }>()
);
export const checkCaseAnalysisState = createAction(
  "[VIEWER_CONTEXT] Check if Case Analysis State exists",
  props<{ caseId: string }>()
);
export const createCaseAnalysisState = createAction(
  "[VIEWER_CONTEXT] Create Case Analysis State",
  props<{ caseId: string }>()
);
export const caseAnalysisStateFetched = createAction(
  "[VIEWER_CONTEXT] Case Analysis State fetched",
  props<{ analysisState: AnalysisState }>()
);
export const roisActionError = createAction(
  "[VIEWER_CONTEXT] Processing failed",
  props<{ error: string }>()
);

export const addMosaicSelectedLabel = createAction(
  "[VIEWER_CONTEXT] Add Label for Filter Mosaics ",
  props<{ label: string }>()
);

export const removeMosaicSelectedLabel = createAction(
  "[VIEWER_CONTEXT] Remove Label for Filter Mosaics ",
  props<{ labels: string[] }>()
);

export const resetMosaicSelectedLabel = createAction(
  "[VIEWER_CONTEXT] Clean Labels for Filter Mosaics "
);

export const loadRefStripElements = createAction(
  "[REFERENCE STRIP] Load reference strip elements into state",
  props<{ refStripItems: ISampleItem[] }>()
);
export const markItemAsViewed = createAction(
  "[REFERENCE STRIP] Mark strip item as viewed",
  props<{ assetId: string }>()
);
export const setFavAnalysis = createAction(
  "[REFERENCE STRIP] Toggle favAnalysis",
  props<{ assetId: string; analysisState: AnalysisState }>()
);
export const markAssetAsReviewed = createAction(
  "[REFERENCE STRIP] Mark strip item as reviewed",
  props<{ assetIds: string[] }>()
);
export const markAssetAsAnalyzed = createAction(
  "[REFERENCE STRIP] Mark strip item as analyzed",
  props<{ assetId: string }>()
);
// REVIEW: ad-hoc for big cells filter
export const markAssetAsLargeCells = createAction(
  "[REFERENCE STRIP] Mark strip item as large cells container",
  props<{ assetId: string; markAs: boolean }>()
);
export const refStripLoaded = createAction(
  "[REFERENCE STRIP] Ref strip elements loaded",
  props<{
    refStripDataResponse: refStripDataResponse[];
    itemsRequested: string[];
    assetIds: string[];
    isAuthUser: boolean;
  }>()
);
export const requestStripData = createAction(
  "[REFERENCE STRIP] Load reference strip data",
  props<{ requestRefStripData: requestRefStripData }>()
);
export const loadAssetIndex = createAction(
  "[REFERENCE STRIP] Change asset index",
  props<{ index: number; step?: number }>()
);
export const assetIndexLoaded = createAction(
  "[REFERENCE STRIP] Asset index loaded",
  props<{ index: number }>()
);
export const setAvailableAnalysts = createAction(
  "[ANALYSTS] Set available analysts",
  props<{ analysisStates: AnalysisState[]; currentUserId: string }>()
);
export const discardMarkAsAnalyzed = createAction(
  "[ANALYSTS] Unmark assets as analyzed",
  props<{ assetId: string; analysisStatesId: string }>()
);

export const loadCaseSampleRef = createAction(
  "[REFERENCES] Load next and previous references for case and sample",
  props<{
    nextCase: Case;
    previousCase: Case;
    nextSample: Sample;
    previousSample: Sample;
    nextCaseSample: Sample;
    prevCaseSample: Sample;
  }>()
);
export const updateAssetFilters = createAction(
  "[ANALYSTS] Update asset filters",
  props<{ filter: string }>()
);

export const loadViewerInfo = createAction(
  "[VIEWER CONFIG] Load viewer config",
  props<{ viewerConfig: ViewerConfig }>()
);

export const noOpAction = createAction("[ROIS] No action");

export const updateMultipleFiltersFlag = createAction(
  "[MOSAIC VIEWER] Update multiple filters flag for mosaic viewer",
  props<{ active: boolean }>()
);
