import { Injectable } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { POI, ROI } from "../../state";

export interface GroupedMosaic {
  category: string;
  labels: { [label: string]: CategoryLabelData };
}

export interface CategoryLabelData {
  uuid: string;
  color: string;
  crops: CropInfo[];
}

export interface CropInfo {
  roiID: string;
  findingId: string;
  crop: Observable<SafeUrl>;
}

@Injectable({
  providedIn: "root",
})
export class DragDropService {
  private dropListIds = new Set<string>();

  private _dropListIdsSubject$ = new Subject<string[]>();
  private _selectedCrops$ = new BehaviorSubject<CropInfo[]>([]);
  private _selectedCropsSelector$ = new BehaviorSubject<(ROI | POI)[]>([]);

  public dropListIdsObservable$ = this._dropListIdsSubject$.asObservable();
  public readonly selectedCrops$ = this._selectedCrops$.asObservable();
  public readonly selectedCropsSelector$ =
    this._selectedCropsSelector$.asObservable();

  addDropListId(id: string): void {
    this.dropListIds.add(id);
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  removeDropListId(id: string): void {
    this.dropListIds.delete(id);
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  clearAll(): void {
    this.dropListIds.clear();
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  getConnectedDropLists(): string[] {
    return Array.from(this.dropListIds);
  }

  // REVIEW: Unify grid and selector components state
  addSelectedCrop(cropInfo: CropInfo): void {
    this._selectedCrops$.next([...this._selectedCrops$.value, cropInfo]);
  }

  addSelectedCropSelector(roiInfo: ROI | POI): void {
    this._selectedCropsSelector$.next([
      ...this._selectedCropsSelector$.value,
      roiInfo,
    ]);
  }

  removeSelectedCrop(cropInfo: CropInfo): void {
    const filtered = this._selectedCrops$.value.filter(
      (crop) => crop !== cropInfo
    );
    this._selectedCrops$.next([...filtered]);
  }

  removeSelectedCropSelector(roiInfo: ROI | POI): void {
    const filtered = this._selectedCropsSelector$.value.filter(
      (crop) => crop !== roiInfo
    );
    this._selectedCropsSelector$.next([...filtered]);
  }

  cleanSelectedCrops(): void {
    this._selectedCrops$.next([]);
  }

  cleanSelectedCropsSelector(): void {
    this._selectedCropsSelector$.next([]);
  }
}
