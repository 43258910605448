<ng-container
  *ngIf="{
    protocolOptions: protocolOptions$ | async,
    assetProtocol: assetProtocol$ | async,
    loading: loading$ | async,
    groupedMosaics: groupedMosaics$ | async
  } as state"
>
  <div class="slider-wrapper">
    <button
      (click)="decreaseValue()"
      class="slider-button ri-zoom-out-line"
    ></button>
    <mat-slider
      min="1"
      max="3"
      step="0.5"
      [(ngModel)]="scale"
      (input)="onSliderChange($event)"
      tickInterval="1"
      aria-label="Mosaic Size"
    ></mat-slider>
    <button
      (click)="increaseValue()"
      class="slider-button ri-zoom-in-line"
    ></button>
  </div>

  <div
    scrollDetector
    (scrolledToBottom)="loadMoreCrops()"
    class="scrollable"
    #scrollContainer
  >
    <div
      *ngFor="let categoryObj of state.groupedMosaics; trackBy: trackByCategory"
      (click)="onOutsideClick()"
    >
      <h2 class="gallery-category">{{ categoryObj.category }}</h2>
      <div
        *ngFor="
          let label of objectKeys(categoryObj.labels);
          trackBy: trackByLabel
        "
      >
        <h3 *ngIf="label !== 'unlabeled'" class="gallery-label">
          {{ label }}
        </h3>
        <div
          *ngIf="categoryObj.labels[label].crops.length > 0"
          class="mosaic-gallery"
          #gallery
          cdkDropList
          [id]="label"
          [cdkDropListData]="categoryObj.labels[label].crops"
          [cdkDropListConnectedTo]="dragDropService.getConnectedDropLists()"
          (cdkDropListDropped)="onDrop($event)"
          cdkDropListSortingDisabled="true"
        >
          <div
            *ngFor="
              let cropInfo of categoryObj.labels[label].crops;
              let i = index;
              trackBy: trackByCropInfo
            "
            class="mosaic-item"
            #cropElement
            [style.borderColor]="
              isLabelHover(cropInfo) && selectedCrops.length > 1
                ? getComplementaryColor(categoryObj.labels[label].color)
                : getColor(categoryObj.labels[label].uuid)
            "
            [style.boxShadow]="
              isSelected(cropInfo)
                ? '0 0 5px 2px ' + categoryObj.labels[label].color
                : 'none'
            "
            [attr.data-roiid]="cropInfo.roiID"
            (click)="onCropClick($event, cropInfo)"
            (contextmenu)="openContextMenu($event, cropInfo)"
            (cdkDragStarted)="onDragStart($event, cropInfo)"
            cdkDrag
            [cdkDragData]="cropInfo"
          >
            <ts-image-placeholder [imageUrl]="getCrop(cropInfo.roiID)">
              <ts-badge [count]="getLabelsHints(cropInfo)"></ts-badge>
            </ts-image-placeholder>

            <ng-template cdkDragPreview *ngIf="selectedCrops.length > 1">
              <div class="drag-preview">
                {{ selectedCrops.length }} {{ "info.items" | translate }}
              </div>
            </ng-template>
          </div>
          <div #selectionBox class="selection-box"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="sticky-bottom" *ngIf="state.loading">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>

  <div class="selected-badge" *ngIf="selectedCrops.length > 0">
    <div>
      {{ selectedCrops.length }} {{ "info.selected_items" | translate }}
    </div>
    <button (click)="deselectAll()">
      {{ "button.unselect_all" | translate }}
    </button>
  </div>

  <ts-label-context-menu
    *ngIf="contextMenuVisible"
    [categories]="state.assetProtocol"
    [checkedOptions]="selectedItemLabels"
    [doubleCheckedOptions]="doubleCheckedOptions"
    (optionSelected)="
      onMenuClick($event.option, $event.category, state.protocolOptions)
    "
    (optionHovered)="onMenuItemEnter($event)"
    (optionUnhovered)="onMenuItemLeave()"
    #contextMenu
    class="fixed"
    [style.top.px]="contextMenuPosition.y"
    [style.left.px]="contextMenuPosition.x"
  ></ts-label-context-menu>
</ng-container>
