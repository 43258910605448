import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, CaseResolver } from "@telespot/web-core";
import { ExitEditorGuard } from "@telespot/shared/util";
import { CaseAnalysisComponent } from "./components/case-analysis/case-analysis.component";
import { SampleAnalysisComponent } from "./components/sample-analysis/sample-analysis.component";

const routes: Routes = [
  {
    path: "cases/:caseid",
    children: [
      {
        path: "samples/:sampleid",
        component: SampleAnalysisComponent,
        canDeactivate: [ExitEditorGuard],
        data: {
          title: "Sample analysis",
        },
      },
      {
        path: "",
        component: CaseAnalysisComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ExitEditorGuard],
        data: {
          allowedRoles: ["admin", "analyst", "analystmanager", "technician"],
          title: "Case analysis",
        },
      },
    ],
    resolve: { case: CaseResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalysisRefactorRoutingModule {}
