import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarAlertComponent } from '@shared/ui';
import { DownloadService } from '@telespot/shared/util';
import { IAsset } from '@telespot/ui';
import { BehaviorSubject } from 'rxjs';

const MAX_ZEROS_PAD_START = 4;

interface DataDialog {
  assets: IAsset[];
  sampleName: string;
  imageFormat: boolean;
}

@Component({
  selector: 'ts-download-assets-dialog',
  templateUrl: './download-assets-dialog.component.html',
  styleUrls: ['./download-assets-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadAssetsDialogComponent {
  private _minAssetSelected = new BehaviorSubject<boolean>(false);
  get minAssetSelected$() {
    return this._minAssetSelected.asObservable();
  }

  constructor(
    @Optional() public dialogRef: MatDialogRef<DownloadAssetsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialog,
    private _snackBar: MatSnackBar,
    private _downloadService: DownloadService
  ) { }

  receivedData(assets: IAsset[]) {
    this.data.assets = assets;
    this._minAssetSelected.next(this.data.assets.some((a) => a.selected));
  }
  async downloadAssets(): Promise<void> {
    const finalAssets = this.data.assets.filter((a) => a.selected);
    const assetsZipName = this.data.assets.length === finalAssets.length ? `ALL` : `${finalAssets.length}`;
    try {
      this._downloadService.createZip(
        finalAssets.map((fa) => {
          const fileName = fa.assetFile;
          const fileStringIndex = (fa.index + 1).toString();
          return {
            urlName: fileName,
            finalName: `${fileStringIndex.padStart(MAX_ZEROS_PAD_START, '0')}_img_${fileName.substring(
              fileName.lastIndexOf('/') + 1
            )}`,
          };
        }),
        `${this.data.sampleName} - ${assetsZipName} images`
      );
      this._snackBar.open('Wait until ZIP download completes', null, { duration: 2000 });
    } catch (err) {
      this._snackBar.openFromComponent(SnackbarAlertComponent, {
        duration: 2000,
        data: {
          title: `ZIP download failed: ${err.message}`,
        },
      });
    }
    this.dialogRef.close();
  }
}
