<div class="acquisition-info">
  <ng-container *ngIf="isObject(acquisitionData)">
    <div *ngFor="let assetField of assetFields">
      <ng-container
        *ngIf="
          extractFieldValue(assetField.fieldPath, acquisitionData) as value
        "
      >
        <div class="key" style="display: inline-block">
          {{ assetField.displayName | titlecase }}:
        </div>
        <div style="display: inline-block">
          {{ value }}
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
