<ng-container
  *ngIf="{
    protocolOptions: protocolOptions$ | async,
    assetProtocol: assetProtocol$ | async,
    categories: categories$ | async,
    labels: filteredLabels$ | async,
    rois: roisObs$ | async,
    loading: loading$ | async
  } as state"
>
  <div style="position: relative">
    <div *ngIf="selectedLabels.length" class="slider-wrapper">
      <button
        (click)="decreaseValue()"
        class="slider-button ri-zoom-out-line"
      ></button>
      <mat-slider
        min="1"
        max="3"
        step="0.5"
        [(ngModel)]="scale"
        (input)="onSliderChange($event)"
        tickInterval="1"
        aria-label="Mosaic Size"
        class="mosaic-slider"
      ></mat-slider>
      <button
        (click)="increaseValue()"
        class="slider-button ri-zoom-in-line"
      ></button>
    </div>

    <div
      scrollDetector
      (scrolledToBottom)="loadMoreCrops()"
      class="scrollable"
      #scrollContainer
    >
      <div class="category-selector-container">
        <i class="ri-search-line" style="margin-right: 10px"></i>
        <mat-form-field style="width: 30vw" id="category-selector">
          <mat-label>{{ "button.filter_by_label" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [formControl]="labelControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="onLabelChange($event.option.value)"
          >
            <mat-optgroup
              *ngFor="let category of state.categories"
              [label]="category"
            >
              <ng-container *ngIf="state.labels">
                <mat-option
                  *ngFor="let label of getLabels(state.labels, category)"
                  [value]="label"
                  [disabled]="disabledLabelOption(label)"
                >
                  {{ label }}
                </mat-option>
              </ng-container>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
        <span *ngIf="selectedLabels.length >= 1" [style.padding]="'5px'"
          ><a
            [style.cursor]="'pointer'"
            [title]="'label.tags_clear' | translate"
            (click)="clearLabels()"
            ><i
              class="ri-close-circle-fill"
              [style.color]="'rgb(0, 153, 187)'"
            ></i
          ></a>
        </span>
      </div>
      <ng-container *ngIf="selectedLabels.length > 1">
        <div class="category-selector-container">
          <span>
            <i class="ri-error-warning-line text-warning"></i>
            {{ "alert.drag_and_drop_disabled" | translate }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="!selectedLabels.length">
        <div class="no-filters-alert">
          <span>
            {{ "info.no_filters_selected" | translate }}
          </span>
        </div>
      </ng-container>

      <div class="filters-selected-container" (click)="onOutsideClick()">
        <ng-container *ngFor="let sl of selectedLabels; let i = index">
          <span class="roi-badge">
            <span
              class="roi-badge__label"
              [style.borderColor]="getLabelColor(sl)"
              [style.color]="getLabelColor(sl)"
              >{{ sl }}
              <i
                class="ri-close-fill roi-badge__close-icon"
                (click)="removeLabelFilter(i, sl)"
              ></i>
            </span>
          </span>
        </ng-container>
      </div>
      <div
        class="mosaic-gallery"
        #gallery
        cdkDropList
        [id]="containerId"
        [cdkDropListConnectedTo]="connectedDropLists"
        (cdkDropListDropped)="onDrop($event)"
        (cdkDropListEntered)="onDragEnter()"
        cdkDropListSortingDisabled="true"
        (click)="onOutsideClick()"
      >
        <ng-container *ngIf="selectedLabels.length >= 1">
          <ng-container *ngIf="state.rois.length; else noRois">
            <ng-container *ngFor="let roi of state.rois; let i = index">
              <div
                class="mosaic-item"
                #cropElement
                [style.borderColor]="
                  isLabelHover(roi) && selectedCrops.length > 1
                    ? getComplementaryColor(getColor())
                    : getColor()
                "
                [style.boxShadow]="
                  isSelected(roi) ? '0 0 5px 2px ' + getColor() : 'none'
                "
                [attr.data-roiid]="roi.id"
                (click)="onCropClick($event, roi)"
                (contextmenu)="openContextMenu($event, roi)"
                (cdkDragStarted)="onDragStart($event, roi)"
                cdkDrag
                [cdkDragData]="{ roiID: roi.id }"
              >
                <ts-image-placeholder [imageUrl]="getCrop(roi.id)">
                  <ts-badge [count]="getLabelsHints(roi)"></ts-badge>
                </ts-image-placeholder>

                <ng-template cdkDragPreview *ngIf="selectedCrops.length > 1">
                  <div class="drag-preview">
                    {{ selectedCrops.length }} {{ "info.items" | translate }}
                  </div>
                </ng-template>
              </div>
              <div #selectionBox class="selection-box"></div>
            </ng-container>
          </ng-container>
          <ng-template #noRois>
            <div class="empty-message-container">
              <span>
                {{ "info.no_analysis_data" | translate | uppercase }}
              </span>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>

    <div class="sticky-bottom" *ngIf="state.loading">
      <mat-progress-bar mode="query"></mat-progress-bar>
    </div>

    <div class="selected-badge" *ngIf="selectedCrops.length > 0">
      <div>
        {{ selectedCrops.length }} {{ "info.selected_items" | translate }}
      </div>
      <button (click)="deselectAll()">
        {{ "button.unselect_all" | translate }}
      </button>
    </div>
  </div>

  <ts-label-context-menu
    *ngIf="contextMenuVisible"
    [categories]="state.assetProtocol"
    [checkedOptions]="selectedItemLabels"
    [doubleCheckedOptions]="doubleCheckedOptions"
    (optionSelected)="
      onMenuClick($event.option, $event.category, state.protocolOptions)
    "
    (optionHovered)="onMenuItemEnter($event)"
    (optionUnhovered)="onMenuItemLeave()"
    #contextMenu
    class="fixed"
    [style.top.px]="contextMenuPosition.y"
    [style.left.px]="contextMenuPosition.x"
  ></ts-label-context-menu>
</ng-container>
