import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { PreprocessFilePipeModule } from '@telespot/shared/util';
import { BehaviorSubject } from 'rxjs';

export interface IAsset {
  id: string;
  selected: boolean;
  thumbnail: string;
  index: number;
  assetFile?: string;
}

@Component({
  selector: 'ts-total-assets-selector',
  templateUrl: './total-assets-selector.component.html',
  styleUrls: ['./total-assets-selector.component.scss'],
})
export class TotalAssetsSelectorComponent implements OnInit {
  @Input() public assets: IAsset[];
  @Input() public imageFormat: boolean;
  @Output() emitter = new EventEmitter<IAsset[]>();

  public asset: IAsset;

  private _selectedAssetIndex = new BehaviorSubject<number>(0);
  get selectedAssetIndex$() {
    return this._selectedAssetIndex.asObservable();
  }

  private _minAssetSelected = new BehaviorSubject<boolean>(false);
  get minAssetSelected$() {
    return this._minAssetSelected.asObservable();
  }

  private _totalAssets$ = new BehaviorSubject<number>(0);
  public totalAssets$ = this._totalAssets$.asObservable();

  ngOnInit(): void {
    if (!this.assets || !this.assets.length) return;
    this.asset = this.assets[0];
  }

  toggleAllImages(event: MatSlideToggleChange) {
    this.assets.forEach((da) => {
      da.selected = event.checked;
    });
    this._totalAssets$.next(event.checked ? this.assets.length : 0);
    this._minAssetSelected.next(event.checked);
    this.emitter.emit(this.assets);
  }

  setAsset(i) {
    this.assets[i].selected = !this.assets[i].selected;
    this.asset = this.assets[i];
    this._totalAssets$.next(this.assets.filter((a) => a.selected).length);
    this._selectedAssetIndex.next(i);
    this._minAssetSelected.next(this.assets.some((a) => a.selected));
    this.emitter.emit(this.assets);
  }
}

@NgModule({
  declarations: [TotalAssetsSelectorComponent],
  imports: [CommonModule, MatSlideToggleModule, TranslateModule, PreprocessFilePipeModule],
  exports: [TotalAssetsSelectorComponent],
  providers: [],
})
export class AssetsSelectorComponentModule { }
