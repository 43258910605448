export enum AssetState {
  SUCEEDED = "suceeded",
  PROCESSING = "processing",
}
export interface ISampleItem {
  thumbnail: string;
  viewed: boolean;
  reviewed?: boolean;
  hidden?: boolean;
  assetIndex: number;
  fav?: boolean;
  analyzed?: boolean;
  favAnalysis?: boolean;
  assetId?: string;
  assetFile?: string;
  fetched: boolean;
  width?: number;
  height?: number;
  largeObjects?: boolean;
  state: AssetState;
}
