import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "ts-acquisition-data",
  templateUrl: "./acquisition-data.component.html",
  styleUrls: ["./acquisition-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcquisitionDataComponent {
  @Input() acquisitionData: any;
  @Input() assetFields: any[];

  public extractFieldValue(fieldPath, acquisitionData) {
    const keys = fieldPath.split(".");

    for (const key of keys) {
      if (!Object.keys(acquisitionData).includes(key)) return null;
      acquisitionData = acquisitionData[key];
    }
    return acquisitionData;
  }

  public isObject(value: any) {
    return typeof value === "object" && value !== null;
  }
}
