import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MasksPluginDirective } from './directives/masks-plugin/masks-plugin.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MasksPluginDirective],
  exports: [MasksPluginDirective],
})
export class TelespotSharedViewersUiModule {}
