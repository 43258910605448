import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { EMPTY, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import {
  clearLabelTracking,
  deleteSyncedItem,
  labelListLoaded,
  loadLabelList,
  loadSyncedItems,
  setReferenceAnalyst,
  syncActionError,
} from "./sync.actions";
import { SyncedItemType } from "./sync.reducer";
import {
  discardAllChanges,
  findingsSynced,
  findingsSyncedSaved,
} from "../analysis";
import { StepTask } from "@telespot/sdk";
import { AnalysisMode, mosaicMode, toogleAnalysisMode } from "../../+state";
import { loadStatsSuccess } from "../sample-counters";
import { selectActiveCount } from "../interfeature.selectors";
import { selectLabelsId } from "../protocol";

@Injectable()
export class SyncEffects {
  constructor(private actions$: Actions, private store$: Store) {}

  findingsSavedInAnalysisMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findingsSynced),
      withLatestFrom(
        this.store$.select(selectActiveCount),
        this.store$.select(mosaicMode)
      ),
      filter(([action, labelCount, mosaicMode]) => !mosaicMode),
      switchMap(([{ idChanges }, labelCount]) => {
        return of(idChanges).pipe(
          mergeMap((_) => [
            loadSyncedItems({
              syncedItems: [
                ...idChanges
                  .filter((c) => c.type === StepTask.POSITION)
                  .filter((c) => !c.emptyData)
                  .map((i) => ({
                    id: i.uuid,
                    versionId: i.current,
                    version: i.version,
                    assetId: i.assetId,
                    type: SyncedItemType.FINDING,
                    nextToken: undefined,
                  })),
              ],
            }),
            loadLabelList({
              labelIds: labelCount.labelCount.map((l) => l.labelId),
            }),
          ]),
          catchError((error) =>
            of(
              syncActionError({
                error: `[findingsSavedInAnalysisMode$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  findingsSavedInMosaicMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findingsSynced),
      withLatestFrom(this.store$.select(mosaicMode)),
      filter(([action, mosaicMode]) => mosaicMode),
      switchMap(([action]) => {
        return of(action).pipe(
          map((_) => findingsSyncedSaved({ syncedItems: [] })),
          catchError((error) =>
            of(
              syncActionError({
                error: `[findingsSavedInMosaicMode$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  sortLabelList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLabelList),
      withLatestFrom(this.store$.select(selectLabelsId)),
      switchMap(([{ labelIds }, protocolLabelIds]) => {
        const protocolLabelIdMap: { [key: string]: number } = {};
        protocolLabelIds.forEach((id, index) => {
          protocolLabelIdMap[id] = index;
        });

        const sortedLabelIds = [...labelIds].sort((a, b) => {
          return protocolLabelIdMap[a] - protocolLabelIdMap[b];
        });

        return of(labelListLoaded({ labelIds: sortedLabelIds }));
      })
    )
  );

  loadLabelInitialTracking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStatsSuccess),
      withLatestFrom(this.store$.select(mosaicMode)),
      filter(([action, mosaicMode]) => mosaicMode),
      switchMap(([{ labelCount }]) => {
        return of(
          loadLabelList({
            labelIds: labelCount.map((l) => l.labelId),
          })
        );
      })
    )
  );

  clearRefAnalyst$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toogleAnalysisMode),
      filter(({ mode }) => mode === AnalysisMode.NORMAL),
      switchMap((_) => {
        return of(setReferenceAnalyst({ analyst: undefined }));
      })
    )
  );

  clearLabelTracking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(discardAllChanges),
      withLatestFrom(this.store$.select(mosaicMode)),
      switchMap(([_, mosaicMode]) => {
        if (!mosaicMode) return EMPTY;
        return of(clearLabelTracking());
      })
    )
  );
}
