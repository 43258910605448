<div class="color-wrapper">
  <ts-color-palette [hue]="hue"  [inputColor]="color" (color)="color = $event; change($event)"></ts-color-palette>
  <ts-color-slider (color)="hue = $event" style="margin-left: 16px"></ts-color-slider>
</div>
<div class="input-wrapper">
  <a *ngIf="color" (click)="hue = undefined; color = undefined; change(undefined)" href=""
    ><i class="ri-close-circle-line"></i
  ></a>
  <span class="text">{{ color ? color : 'Elige color o se asignará por defecto' }}</span>
  <div *ngIf="color" class="color-div" [ngStyle]="{ 'background-color': color || 'white' }"></div>
</div>
