import { CommonModule } from "@angular/common";
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  NgModule,
  Output,
} from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[scrollDetector]",
})
export class ScrollDetectorDirective {
  @Output() scrolledToBottom = new EventEmitter<void>();

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  @HostListener("scroll")
  onScroll(): void {
    const { scrollTop, clientHeight, scrollHeight } =
      this.elementRef.nativeElement;

    // Check if scrolled to the bottom with a tolerance
    const atBottom = scrollHeight - Math.round(scrollTop) === clientHeight;
    if (atBottom) this.scrolledToBottom.emit();
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ScrollDetectorDirective],
  exports: [ScrollDetectorDirective],
})
export class ScrollDetectorDirectiveModule {}
