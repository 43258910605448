<div
  id="sample-actions-container"
  *ngIf="{
    layout: layout$ | async,
    asset: selectedAsset$ | async,
    availableIA: availableAIanalysis$ | async,
    isViewMode: isViewMode$ | async,
    numAssets: numAssets$ | async,
    processingAIstate: processingAI$ | async,
    unsaved: hasUnsavedData$ | async,
    syncing: syncing$ | async
  } as state"
>
  <div
    data-cy="analysis-sample-actions"
    id="actions-strip"
    class="flex-row justify-content-end"
  >
    <ng-container *ngIf="state.layout === 'mobile'; else toolbarTemplate">
      <button
        class="toolbar-action btn float-right"
        #btn
        [matMenuTriggerFor]="menu"
      >
        <i fxFlex class="ri-tools-line"></i>
      </button>
      <mat-menu #menu="matMenu" class="setting-menu">
        <ng-container [ngTemplateOutlet]="toolsTemplate"></ng-container>
      </mat-menu>
    </ng-container>
    <ng-template #toolbarTemplate>
      <div class="toolbar-action flex-row">
        <ng-container [ngTemplateOutlet]="toolsTemplate"></ng-container>
      </div>
    </ng-template>

    <ng-template #toolsTemplate>
      <!-- <ng-container *ngIfRole="['admin']; features: ['secondOpinion']">
        <button
          mat-menu-item
          class="toolbar-action"
          [routerLink]="[]"
          queryParamsHandling="merge"
          [matTooltip]="'tooltip.panel_second_opinion' | translate"
          (click)="togglePanel('secondOpinion')"
          data-cy="review-second-opinion"
        >
          <i class="ri-question-answer-line"></i>
        </button>
      </ng-container> -->

      <ng-container *ngIf="state.layout === 'web' && mosaicMode">
        <button
          mat-menu-item
          class="toolbar-action"
          [routerLink]="[]"
          [matTooltip]="'tooltip.panel_microscope_view' | translate"
          (click)="toggleAnalysisMode('normal')"
          data-cy="mosaics-view-button"
          [disabled]="state.syncing"
        >
          <i class="ri-microscope-line"></i>
        </button>
      </ng-container>

      <ng-container *ngIf="state.layout === 'web' && !mosaicMode">
        <button
          mat-menu-item
          class="toolbar-action"
          [routerLink]="[]"
          [matTooltip]="'tooltip.panel_mosaics_view' | translate"
          (click)="toggleAnalysisMode('mosaic')"
          data-cy="mosaics-view-button"
          [disabled]="mosaicButtonDisabled$ | async"
        >
          <i class="ri-grid-line"></i>
        </button>
      </ng-container>

      <ng-container *ngIf="state.layout === 'web' && !mosaicMode">
        <ng-container
          *ngIfRole="
            ['admin', 'analystmanager', 'analyst'];
            features: ['analysisReview']
          "
        >
          <button
            mat-menu-item
            class="toolbar-action"
            data-cy="review-other-analyst"
            matTooltip="{{ 'tooltip.panel_analysis_review' | translate }}"
            [routerLink]="[]"
            queryParamsHandling="merge"
            (click)="togglePanel('analyst')"
          >
            <i class="ri-group-line"></i>
          </button>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="state.layout === 'web' && state.availableIA && !mosaicMode"
      >
        <button
          mat-menu-item
          class="toolbar-action"
          matTooltip="{{ 'tooltip.panel_ai' | translate }}"
          [routerLink]="['./']"
          queryParamsHandling="merge"
          (click)="togglePanel('ai')"
          data-cy="tab-ai"
        >
          <i
            class="ri-robot-line"
            [class.in-progress]="sectorProcessing$ | async"
          ></i>
        </button>
      </ng-container>

      <!-- <ng-container *ngIfRole="['admin']; features: ['secondOpinion']">
        <button
          mat-menu-item
          data-cy="request-second-opinion"
          class="toolbar-action"
          [routerLink]="[]"
          queryParamsHandling="merge"
          matTooltip="{{ 'tooltip.request_second_opinion' | translate }}"
          (click)="!state.isViewMode ? secondOpinionEvent() : false"
        >
          <i class="ri-share-line" [class.disabled]="state.isViewMode"></i>
        </button>
      </ng-container> -->

      <ng-container *ngIf="!mosaicMode">
        <a
          mat-menu-item
          *ngIfRole="['admin', 'analyst', 'analystmanager', 'technician']"
          class="toolbar-action"
          matTooltip="{{ 'button.download_original_image' | translate }}"
          [href]="(selectedAsset$ | async)?.assetFileURL + '?download'"
          authHref
          [download]="(selectedAsset$ | async)?.assetFileName"
          (click)="downloadAsset()"
          target="_self"
          tsAnalytics="download_asset"
          data-cy="button-download-asset"
        >
          <i class="ri-download-2-line"></i>
        </a>
      </ng-container>
      <ng-container
        *ngIf="state.layout !== 'mobile' && state.numAssets > 1 && !mosaicMode"
      >
        <button
          mat-menu-item
          *ngIfRole="['admin', 'analyst', 'analystmanager', 'technician']"
          class="toolbar-action"
          matTooltip="{{ 'button.download_zip_images' | translate }}"
          [routerLink]="['./']"
          queryParamsHandling="merge"
          (click)="downloadEvent()"
          target="_self"
          data-cy="button-download-assets-zip"
        >
          <i class="ri-folder-download-line"></i>
        </button>
      </ng-container>
      <ng-container *ngIf="state.layout === 'web'">
        <button
          [disabled]="state.syncing || !state.unsaved"
          mat-menu-item
          class="toolbar-action"
          matTooltip="{{ 'button.save' | translate }}"
          [routerLink]="['./']"
          queryParamsHandling="merge"
          [class.bg-warning]="state.unsaved && !state.syncing"
          [class.text-white]="state.unsaved"
          (click)="saveEvent()"
          *ngIfRole="['admin', 'analyst', 'analystmanager']"
          data-cy="toolbar-save-analysis"
          tsAnalytics="save_sample_analysis"
        >
          <mat-spinner
            *ngIf="state.syncing && !state.synced; else saveIcon"
            class="relative-spinner"
            value="indeterminate"
            [diameter]="20"
          ></mat-spinner>
          <ng-template #saveIcon>
            <i class="ri-save-line"></i>
          </ng-template>
        </button>
      </ng-container>

      <!-- <ng-container *ngIfRole="['!analyst', '!analystmanager', '!technician', '!admin']">
        <button
          mat-menu-item
          class="toolbar-action"
          matTooltip="{{ 'tooltip.send_second_opinion' | translate }}"
          [routerLink]="[]"
          queryParamsHandling="merge"
          (click)="saveEvent()"
          data-cy="toolbar-submit-second-opinion"
        >
          <i class="ri-send-plane-2-line"></i>
        </button>
      </ng-container> -->

      <ng-container *ngIf="state.layout === 'web'">
        <a
          mat-menu-item
          class="toolbar-action"
          matTooltip="{{ 'button.close' | translate }}"
          (click)="exit()"
          [routerLink]="[]"
          queryParamsHandling="merge"
          *ngIfRole="['admin', 'analyst', 'analystmanager', 'technician']"
          data-cy="toolbar-exit"
        >
          <i class="ri-close-fill"></i>
        </a>
      </ng-container>
    </ng-template>
  </div>
  <mat-expansion-panel
    class="custom-mat-expansion-panel"
    #actionExpPanel="matExpansionPanel"
    [expanded]="(panelContext$ | async) !== undefined"
  >
    <ng-container [ngSwitch]="panelContext$ | async">
      <ng-container #AIpanelTemplate *ngSwitchCase="'ai'">
        <div class="action-panel">
          <ts-ai-analysis-selection-panel></ts-ai-analysis-selection-panel>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'analyst'">
        <div class="action-panel">
          <ts-analyst-selector></ts-analyst-selector>
        </div>
      </ng-container>
      <!-- <ng-container *ngSwitchCase="'secondOpinion'">
        <div class="action-panel">
          <ts-second-opinion-selector></ts-second-opinion-selector>
        </div>
      </ng-container> -->
    </ng-container>
  </mat-expansion-panel>
</div>
