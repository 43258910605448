import { CdkDragDrop, CdkDragStart } from "@angular/cdk/drag-drop";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  AnalysisLabel,
  DragDropService,
  Label,
  POI,
  ProtocolSpecification,
  ROI,
  RoiService,
  addMosaicSelectedLabel,
  categoryLabels,
  changeCropLabels,
  dragCrop,
  labelValues,
  mosaicSelectedLabels,
  loadMore,
  multipleFiltersActive,
  removeMosaicSelectedLabel,
  roisSorted,
  updateMultipleFiltersFlag,
  resetMosaicSelectedLabel,
  CropInfo,
  selectLoadingAnalysis,
  getProtocolTasks,
  getProtocolOptions,
  preSyncAnalysis,
} from "@telespot/analysis-refactor/data-access";

import { MosaicService } from "@telespot/web-core";
import { Observable, Subject, combineLatest } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import generateId from "uid";
import { validate as uuidValidate } from "uuid";
import { FormControl } from "@angular/forms";
import { OS } from "../sample-analysis-mosaics/sample-analysis-mosaics.component";
import { MatSliderChange } from "@angular/material/slider";
import { TAnalysisProtocolTask, TaskOption } from "@telespot/sdk";

@Component({
  selector: "ts-sample-analysis-mosaics-selector",
  templateUrl: "./sample-analysis-mosaics-selector.component.html",
  styleUrls: ["./sample-analysis-mosaics-selector.component.scss"],
})
export class SampleAnalysisMosaicsSelectorComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() useGlobalState = false;

  public unlabeledCategory = {
    en: "Unlabeled",
    es: "Sin etiquetar",
    fr: "Non etiqueté",
    pt: "Não rotulado",
  };

  public unlabeledOption = {
    en: "All unlabeled",
    es: "No etiquetadas",
    fr: "Non etiqueté",
    pt: "Não rotulado",
  };

  public UNLABELED_COLOR = "#F08F43";
  public MOVE_THRESHOLD = 10;
  labelControl = new FormControl();

  // Contextual Menu variables
  public contextMenuVisible = false;
  public contextMenuPosition = { x: 0, y: 0 };
  public scale = 1;
  public selectedItemLabels: Array<string> = [];

  @ViewChild("contextMenu", { read: ElementRef })
  private contextMenuRef: ElementRef;

  @ViewChild("scrollContainer") scrollContainer: ElementRef;

  @ViewChildren("gallery") galleries: QueryList<ElementRef>;
  @ViewChildren("selectionBox") selectionBoxes!: QueryList<ElementRef>;

  isDragging = false;
  startPoint = { x: 0, y: 0 };
  draggedSelection = false;
  currentGallery: ElementRef;
  private currentSelectionBox!: ElementRef;
  private draggingCrops = false;

  // Protocol and Mosaics variables
  @Input() protocol: ProtocolSpecification[] = [];

  public readonly assetProtocol$ = this._store.select(getProtocolTasks);
  public readonly protocolOptions$ = this._store.select(getProtocolOptions);
  public readonly loading$ = this._store.select(selectLoadingAnalysis);
  public readonly rois$ = this._store.select(roisSorted);
  public multipleFiltersActive$ = this._store.select(multipleFiltersActive);
  public roisObs$: Observable<(ROI | POI)[]>;
  public currentLang;
  public containerId = "";
  public multipleFiltersActive;
  public selectedCrops: (ROI | POI)[];

  // Selector variables
  public selectedLabels: string[] = [];
  public protocolLabels;

  public labels$ = this._store.select(labelValues).pipe(
    map((labels) => {
      const allLabels = [
        ...labels,
        {
          category: this.unlabeledCategory[this.currentLang ?? "en"],
          value: this.unlabeledOption[this.currentLang ?? "en"],
        },
      ];
      this.protocolLabels = allLabels;
      return allLabels;
    })
  );

  public filteredLabels$ = combineLatest([
    this.labels$,
    this.labelControl.valueChanges.pipe(startWith("")),
  ]).pipe(map(([labels, searchTerm]) => this.filterLabels(labels, searchTerm)));

  public categories$ = combineLatest([
    this._store.select(categoryLabels),
    this.filteredLabels$,
  ]).pipe(
    map(([categories, filteredLabels]) => {
      const allCategories = [
        ...categories,
        this.unlabeledCategory[this.currentLang ?? "en"],
      ];

      return allCategories.filter((category) =>
        filteredLabels.find((l) => l.category === category)
      );
    })
  );

  // Drag and Drop variables
  private _rois: (ROI | POI)[] = [];
  public connectedDropLists$ = this.dragDropService.dropListIdsObservable$;
  public connectedDropLists = [];
  private _isSelecting = false;

  // Component LifeCycle variables
  private destroy$ = new Subject<void>();
  private registeredDropListIds: string[] = [];

  private _os: OS;
  public hoverLabel = "";

  constructor(
    public dragDropService: DragDropService,
    private _mosaicService: MosaicService,
    private _roiService: RoiService,
    private _cdr: ChangeDetectorRef,
    private _store: Store,
    private translateService: TranslateService
  ) {
    this.currentLang =
      localStorage.getItem("user_language") ||
      this.translateService.currentLang;
  }

  /* COMPONENT LYFECYCLE MANAGEMENT FUNCTIONS */

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.useGlobalState) return;

    this._store
      .select(mosaicSelectedLabels)
      .pipe(takeUntil(this.destroy$))
      .subscribe((labels) => {
        this.selectedLabels = this.useGlobalState
          ? labels
          : this.selectedLabels ?? [];
        this.containerId = this.getContainerId();
        this.updateCrops();
      });
    combineLatest([this.multipleFiltersActive$, this.connectedDropLists$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([multipleFiltersActive, lists]) => {
        this.multipleFiltersActive = this.useGlobalState
          ? multipleFiltersActive
          : this.selectedLabels.length > 1;

        if (this.multipleFiltersActive) {
          const listFromSelectors = lists.filter((l) => !uuidValidate(l));
          return (this.connectedDropLists = listFromSelectors);
        }

        this.connectedDropLists = lists;
        this._cdr.detectChanges();
      });
  }

  ngOnInit() {
    this.rois$
      .pipe(takeUntil(this.destroy$))
      .subscribe((rois) => (this._rois = rois));

    this.dragDropService.selectedCropsSelector$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedCrops) => {
        this.selectedCrops =
          this.useGlobalState || this.draggingCrops
            ? selectedCrops
            : this.selectedCrops ?? [];

        this.draggingCrops = false;
      });

    this.containerId = this.getContainerId();
    this.updateRegisteredDropList(this.containerId);
    this.connectedDropLists = this.dragDropService.getConnectedDropLists();

    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.registeredDropListIds.forEach((id) =>
      this.dragDropService.removeDropListId(id)
    );
  }

  @HostListener("document:keydown", ["$event"])
  documentKeyDown(event: KeyboardEvent): void {
    if (event.metaKey || event.ctrlKey) {
      this._isSelecting = true;
      if (event.ctrlKey) {
        this._os = window.navigator.userAgent.toLowerCase().includes("windows")
          ? OS.Windows
          : window.navigator.userAgent.toLowerCase().includes("mac os")
          ? OS.MacOs
          : OS.Linux;
      }
      if (event.key === "s" || event.key === "S") {
        this._store.dispatch(preSyncAnalysis());
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  @HostListener("document:keyup", ["$event"])
  documentKeyUp(event: KeyboardEvent): void {
    const key = event.key.toLowerCase();
    const metaKey = "meta";
    const ctrlKey = "control";

    if (key === metaKey || key === ctrlKey) {
      this._isSelecting = false;
      if (key === ctrlKey) {
        this._os = undefined;
      }
    }
  }

  /* SELECTOR FUNCTIONS */

  public getLabels(labels: Label[], category: string): string[] {
    return labels
      .filter((item) => item.category === category)
      .map((l) => l.value);
  }

  public onLabelChange(label: string) {
    const task = this.protocol
      .flatMap((pipeline) => pipeline.tasks)
      .filter((step) => step.options)
      .find((step) => step.options.some((option) => option.name === label));

    const labelsToReplace =
      task?.options
        .filter((option) => this.selectedLabels.includes(option.name))
        .map((option) => option.name) ?? [];

    if (this.useGlobalState) {
      this._store.dispatch(
        removeMosaicSelectedLabel({ labels: labelsToReplace })
      );
      this._store.dispatch(addMosaicSelectedLabel({ label }));
    } else {
      if (labelsToReplace.length) {
        labelsToReplace.forEach((label) => {
          const index = this.selectedLabels.indexOf(label);
          this.selectedLabels.splice(index, 1);
        });
      }

      this.selectedLabels.push(label);
      this.updateCrops();
    }

    if (this.selectedLabels.length > 1) {
      this._store.dispatch(updateMultipleFiltersFlag({ active: true }));
    }
    this._store.dispatch(loadMore({ labelId: this.getLabelUuid(label) }));

    this.clearRegisteredDropList();
    this.containerId = this.getContainerId();
    this.labelControl.setValue(undefined);

    this.updateRegisteredDropList(this.containerId);

    this._cdr.detectChanges();
  }

  public removeLabelFilter(index: number, label: string) {
    if (this.useGlobalState) {
      this._store.dispatch(removeMosaicSelectedLabel({ labels: [label] }));
    } else {
      this.selectedLabels.splice(index, 1);
      this.updateCrops();
    }

    if (this.selectedLabels.length <= 1)
      this._store.dispatch(updateMultipleFiltersFlag({ active: false }));

    this.containerId = this.getContainerId();
    this.updateRegisteredDropList(this.containerId);

    this.scale = 1;
    this.applyScale();

    this._cdr.detectChanges();
  }

  /* CONTEXTUAL MENU FUNCTIONS */

  @HostListener("document:click", ["$event"])
  documentClick(event: MouseEvent): void {
    const clickedInsideScrollContainer =
      this.scrollContainer?.nativeElement.contains(event.target);

    if (!clickedInsideScrollContainer) {
      this.selectedCrops = [];
    }

    if (!this.contextMenuRef?.nativeElement.contains(event.target)) {
      this.closeContextMenu();
    }
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(event: MouseEvent): void {
    if (!this._isSelecting || this.selectedCrops.length) return;
    const targetGallery = (event.target as HTMLElement).closest(
      ".mosaic-gallery"
    );
    if (!targetGallery) return;

    this.currentGallery = this.galleries.find(
      (gallery) => gallery.nativeElement === targetGallery
    );

    this.currentSelectionBox = this.selectionBoxes.find(
      (box) =>
        box.nativeElement.parentElement === this.currentGallery.nativeElement
    );

    if (!this.currentGallery || !this.currentSelectionBox) return;
    this.isDragging = false;

    const galleryRect =
      this.currentGallery.nativeElement.getBoundingClientRect();

    this.startPoint = {
      x: event.clientX - galleryRect.left,
      y: event.clientY - galleryRect.top,
    };

    const selectionBoxElem = this.currentSelectionBox.nativeElement;
    selectionBoxElem.style.position = "absolute";
    selectionBoxElem.style.left = `${this.startPoint.x}px`;
    selectionBoxElem.style.top = `${this.startPoint.y}px`;
    selectionBoxElem.style.width = "0px";
    selectionBoxElem.style.height = "0px";
    selectionBoxElem.style.display = "block";
  }

  @HostListener("mousemove", ["$event"])
  onMouseMove(event: MouseEvent): void {
    if (!this._isSelecting || !this.currentGallery || !this.currentSelectionBox)
      return;

    const distanceX = event.clientX - this.startPoint.x;
    const distanceY = event.clientY - this.startPoint.y;
    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

    // Check if the movement distance exceeds the threshold
    if (distance > this.MOVE_THRESHOLD) {
      this.isDragging = true; // Update dragging state
    }

    const containerRect =
      this.currentGallery.nativeElement.getBoundingClientRect();
    const currentPoint = {
      x: event.clientX - containerRect.left,
      y: event.clientY - containerRect.top,
    };
    const width = currentPoint.x - this.startPoint.x;
    const height = currentPoint.y - this.startPoint.y;

    const selectionBoxElem = this.currentSelectionBox.nativeElement;
    // Update selection box dimensions
    selectionBoxElem.style.width = `${Math.abs(width)}px`;
    selectionBoxElem.style.height = `${Math.abs(height)}px`;

    if (width < 0) {
      selectionBoxElem.style.left = `${currentPoint.x}px`;
    }
    if (height < 0) {
      selectionBoxElem.style.top = `${currentPoint.y}px`;
    }
  }

  @HostListener("mouseup", ["$event"])
  onMouseUp(): void {
    if (this.isDragging && this._isSelecting) {
      this.checkSelection();
      this.currentGallery = null;
      this.currentSelectionBox.nativeElement.style.display = "none";
      this.draggedSelection = true;
      this.isDragging = false;
    }
  }

  checkSelection(): void {
    if (!this.currentGallery || !this.currentSelectionBox) return;
    const selectionRect =
      this.currentSelectionBox.nativeElement.getBoundingClientRect();

    const crops = Array.from(
      this.currentGallery.nativeElement.querySelectorAll(".mosaic-item")
    );

    this.dragDropService.cleanSelectedCropsSelector();

    crops.forEach((crop: HTMLElement) => {
      const cropRect = crop.getBoundingClientRect();

      if (this.intersects(selectionRect, cropRect)) {
        const roiId = crop.getAttribute("data-roiid");
        const roi = this._rois.find((roi) => roi.id === roiId);

        if (!this.cropIsIncluded(this.selectedCrops, roi)) {
          this.useGlobalState
            ? this.dragDropService.addSelectedCropSelector(roi)
            : this.selectedCrops.push(roi);
        }
      }
    });
  }

  intersects(rect1, rect2): boolean {
    return !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom
    );
  }

  public openContextMenu(event: MouseEvent, roi: ROI | POI): void {
    event.preventDefault();
    event.stopPropagation();

    if (this._os && this._os === OS.MacOs && event.button === 0) {
      this.onCropClick(event, roi);
      return;
    }

    if (this._isSelecting) {
      if (!this.selectedCrops.includes(roi)) {
        this.useGlobalState
          ? this.dragDropService.addSelectedCropSelector(roi)
          : this.selectedCrops.push(roi);
      }
    } else {
      if (!this.selectedCrops.includes(roi)) {
        this.useGlobalState
          ? this.dragDropService.cleanSelectedCropsSelector()
          : (this.selectedCrops = []);
        this.useGlobalState
          ? this.dragDropService.addSelectedCropSelector(roi)
          : this.selectedCrops.push(roi);
      }
    }

    this.contextMenuPosition.x = event.clientX;
    this.contextMenuPosition.y = event.clientY;

    const labels = this.selectedCrops.flatMap((crop) =>
      this.getRoiLabels(crop.labels)
    );

    labels.forEach((uuid) => this.selectedItemLabels.push(uuid));

    this.contextMenuVisible = true;
    this._cdr.detectChanges();

    const rect = this.contextMenuRef.nativeElement.getBoundingClientRect();
    const bottomSpace = window.innerHeight - event.clientY;

    if (rect.height > bottomSpace) {
      this.contextMenuPosition.y -= rect.height;
    }
  }

  public closeContextMenu(): void {
    this.contextMenuVisible = false;
    this.selectedItemLabels = [];
  }

  public getLabelsFromRoi(cropInfo: CropInfo) {
    return Object.keys(
      this._rois
        .find((roi) => {
          return roi.id === cropInfo.roiID;
        })
        .labels.find((label) => label.findingId === cropInfo.findingId).labels
    );
  }

  public showCrop(labels: AnalysisLabel[]) {
    const roiLabels = (this.getRoiLabels(labels) || []).map((l) =>
      this.getLabelValue(l)
    );

    //Show unlabeled crops when selected Label is allUnlabeled
    if (roiLabels?.length === 0 && this.isAllUnlabeledSelected()) {
      return true;
    }

    return this.selectedLabels.every((l) => roiLabels.includes(l));
  }

  public get doubleCheckedOptions(): string[] {
    if (this.selectedCrops.length < 2) return [];

    return this.selectedItemLabels.filter((labelId) =>
      this.isLabelInAllCrops(this.getLabelValue(labelId))
    );
  }

  // REVIEW: preferable use state or service to reduce duplication
  public onMenuClick(
    selectedLabel: TaskOption,
    selectedCategory: TAnalysisProtocolTask,
    protocolLabels: TaskOption[]
  ): void {
    const labelsToReplace = selectedCategory.options.filter((option) =>
      this.selectedItemLabels.includes(option.uuid)
    );

    const unselectLabel =
      labelsToReplace.length === 1 &&
      labelsToReplace[0].name === selectedLabel.name;

    const isLabelSelected = this.selectedItemLabels.includes(
      selectedLabel.uuid
    );
    const isInAllCrops = this.isLabelInAllCrops(selectedLabel.name);

    let actions;

    if (isLabelSelected && isInAllCrops) {
      this.selectedItemLabels = this.selectedItemLabels.filter(
        (l) => l !== selectedLabel.uuid
      );

      actions = this.selectedCrops.map((crop) =>
        changeCropLabels({
          roiId: crop.id,
          findingId: crop.labels[0].findingId,
          newLabel: selectedLabel.uuid,
        })
      );
    } else if (isLabelSelected) {
      const crops = this.getCropsWithoutLabel(selectedLabel.name);

      actions = crops.map((crop) =>
        changeCropLabels({
          roiId: crop.id,
          findingId: crop.labels[0].findingId,
          newLabel: selectedLabel.uuid,
        })
      );
    } else if (labelsToReplace.length && !unselectLabel) {
      this.selectedItemLabels = protocolLabels
        .filter((l) => !labelsToReplace.some((rl) => rl.uuid === l.uuid))
        .map((l) => l.uuid);

      actions = this.selectedCrops.map((crop) =>
        dragCrop({
          roiId: crop.id,
          previousLabels: labelsToReplace.map((label) => label.uuid),
          newLabels: [selectedLabel.uuid],
        })
      );

      this.dragDropService.cleanSelectedCropsSelector();

      this.closeContextMenu();
    } else {
      actions = this.selectedCrops.map((crop) =>
        changeCropLabels({
          roiId: crop.id,
          findingId: crop.labels[0].findingId,
          newLabel: selectedLabel.uuid,
        })
      );

      this.selectedItemLabels.push(selectedLabel.uuid);
    }

    actions.forEach((a) => this._store.dispatch(a));

    if (unselectLabel) this.closeContextMenu();
  }

  /* DRAG AND DROP FUNCTIONS */

  public onDragStart(event: CdkDragStart, roi: ROI | POI): void {
    if (this.contextMenuVisible) this.closeContextMenu();

    if (!this.selectedCrops.includes(roi)) {
      this.useGlobalState
        ? this.dragDropService.cleanSelectedCropsSelector()
        : (this.selectedCrops = []);
      this.useGlobalState
        ? this.dragDropService.addSelectedCropSelector(roi)
        : this.selectedCrops.push(roi);
    }

    if (this._isSelecting && !this.selectedCrops.includes(roi))
      this.useGlobalState
        ? this.dragDropService.addSelectedCropSelector(roi)
        : this.selectedCrops.push(roi);

    event.source.data = this.selectedCrops;

    this.selectedCrops.forEach((crop) => {
      const element = document.querySelector(`[data-roiid="${crop.id}"]`);
      if (element) {
        element.classList.add("dragging");
      }
    });
    this.draggingCrops = true;
  }

  public onDragEnter(): void {
    if (this.contextMenuVisible) this.closeContextMenu();
  }

  public onDrop(event: CdkDragDrop<(ROI | POI)[]>) {
    if (event.previousContainer !== event.container) {
      const origin = this.getLabelsFromContainerId(
        event.previousContainer.id
      ).map((l) => this.getLabelUuid(l));
      const dest = this.getLabelsFromContainerId(event.container.id).map((l) =>
        this.getLabelUuid(l)
      );

      event.item.data.forEach((draggedCropInfo) => {
        const roiID: string = draggedCropInfo.id;
        this._store.dispatch(
          dragCrop({
            roiId: roiID,
            previousLabels: origin,
            newLabels: dest,
          })
        );
      });
    }

    event.item.data.forEach((crop) => {
      const element = document.querySelector(`[data-roiid="${crop.id}"]`);
      if (element) {
        element.classList.remove("dragging");
      }
    });

    this.selectedCrops = [];
    this.dragDropService.cleanSelectedCropsSelector();
  }

  /* HELPER FUNCTIONS */

  public objectKeys(obj: Record<string, unknown>): string[] {
    return Object.keys(obj); // Convert object keys into a string array and return them.
  }

  public getCrop(roiId: string) {
    const url = localStorage.getItem(`crop_${roiId}`);
    if (!url) {
      return null;
    }
    const sanitizedUrl = this._mosaicService.getCropSanitizedUrl(url);
    return sanitizedUrl;
  }

  getRoiLabels(labels: AnalysisLabel[]) {
    return labels.reduce(
      (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
      []
    );
  }

  getColor() {
    if (this.isAllUnlabeledSelected()) return this.UNLABELED_COLOR;

    return this._roiService.getModelColor(
      this.selectedLabels.map((l) => this.getLabelUuid(l)),
      true
    );
  }

  getLabelColor(label) {
    const unlabeled = this.unlabeledOption[this.currentLang ?? "en"];
    if (label === unlabeled) return this.UNLABELED_COLOR;
    return this._roiService.getModelColor([this.getLabelUuid(label)], true);
  }

  getLabelValue(uuid: string) {
    return (this.protocolLabels || []).find((l) => l.uuid === uuid)?.value;
  }

  getLabelUuid(value: string) {
    return (this.protocolLabels || []).find((l) => l.value === value)?.uuid;
  }

  getContainerId() {
    const id = this.selectedLabels.join("/") + "+" + generateId(4);
    return id;
  }

  getSafeUrl(filename) {
    return this._mosaicService.getCropURL(filename);
  }

  isAllUnlabeledSelected() {
    return this.selectedLabels.includes(
      this.unlabeledOption[this.currentLang ?? "en"]
    );
  }

  getAlternativeText() {
    return this.selectedLabels.join(",");
  }

  getLabelsFromContainerId(id: string) {
    const labelsId = id.substring(0, id.length - 5);
    return labelsId.split("/");
  }

  clearRegisteredDropList() {
    this.registeredDropListIds.forEach((id) =>
      this.dragDropService.removeDropListId(id)
    );
    this.registeredDropListIds = [];
  }

  updateRegisteredDropList(id: string) {
    this.clearRegisteredDropList();
    this.dragDropService.addDropListId(id);
    this.registeredDropListIds.push(id);
  }

  disabledLabelOption(label) {
    const unlabeledOption = this.unlabeledOption[this.currentLang ?? "en"];
    const isUnlabeledOption = unlabeledOption === label;
    if (isUnlabeledOption && this.selectedLabels.length > 0) return true;
    if (this.selectedLabels.includes(unlabeledOption)) return true;
    return this.selectedLabels.includes(label);
  }

  filterLabels(
    labels:
      | Label[]
      | {
          category: unknown;
          value: unknown;
        }[],
    searchTerm: string
  ) {
    if (!searchTerm) return labels;
    const filterValue = searchTerm.toLowerCase();

    return labels.filter((l) => l?.value.toLowerCase().includes(filterValue));
  }

  clearLabels() {
    if (this.useGlobalState) {
      this._store.dispatch(resetMosaicSelectedLabel());
      this.dragDropService.cleanSelectedCropsSelector();
    } else {
      this.selectedLabels = [];
      this.selectedCrops = [];
    }

    this.scale = 1;
    this.applyScale();
  }

  updateCrops() {
    this.roisObs$ = this.rois$.pipe(
      map((rois) => rois.filter((roi) => this.showCrop(roi.labels)))
    );
  }

  loadMoreCrops() {
    this._store.dispatch(
      loadMore({
        labelId: this.selectedLabels?.length
          ? this.getLabelUuid(this.selectedLabels[0])
          : undefined,
      })
    );
  }

  getLabelsHints(roiInfo: ROI | POI): number {
    const labels = Object.keys(
      this._rois
        .find((roi) => {
          return roi.id === roiInfo.id;
        })
        .labels.find((label) => label.findingId === roiInfo.labels[0].findingId)
        .labels
    );

    return labels?.length || 0;
  }

  public deselectAll() {
    if (this.contextMenuVisible) this.closeContextMenu();

    this.useGlobalState
      ? this.dragDropService.cleanSelectedCropsSelector()
      : (this.selectedCrops = []);
  }

  onOutsideClick(): void {
    if (this.contextMenuVisible) this.closeContextMenu();
    if (this.draggedSelection) {
      //Avoid cleaning selected crops just after of selecting crops through dragging
      this.draggedSelection = false;
      return;
    }
    this.useGlobalState
      ? this.dragDropService.cleanSelectedCropsSelector()
      : (this.selectedCrops = []);
  }

  isSelected(roiInfo: ROI | POI): boolean {
    return this.selectedCrops.some((crop) => crop.id === roiInfo.id);
  }

  onCropClick(event: MouseEvent, roiInfo: ROI | POI): void {
    if (this.contextMenuVisible) this.closeContextMenu();

    if (!this._isSelecting) {
      this.useGlobalState
        ? this.dragDropService.cleanSelectedCropsSelector()
        : (this.selectedCrops = []);
      event.stopPropagation();
      return;
    }

    this.cropIsIncluded(this.selectedCrops, roiInfo)
      ? this.useGlobalState
        ? this.dragDropService.removeSelectedCropSelector(roiInfo)
        : this.removeSelectedCrop(roiInfo)
      : this.useGlobalState
      ? this.dragDropService.addSelectedCropSelector(roiInfo)
      : this.selectedCrops.push(roiInfo);

    event.stopPropagation();
  }

  onMenuItemEnter(label) {
    this.hoverLabel = label.value;
  }
  onMenuItemLeave() {
    this.hoverLabel = "";
  }

  public isLabelInAllCrops(labelName: string): boolean {
    return (
      this.getCropsWithLabel(labelName).length === this.selectedCrops.length
    );
  }

  public getCropsWithLabel(labelName: string): (ROI | POI)[] {
    const crops = this.selectedCrops.filter((crop) => {
      const roi = this._rois.find((roi) => roi.id === crop.id);
      const labels = roi.labels.find(
        (label) => label.findingId === crop.labels[0].findingId
      ).labels;
      return Object.keys(labels).includes(
        this.protocolLabels.find((label) => label.value === labelName)?.uuid
      );
    });

    return crops;
  }

  public getCropsWithoutLabel(labelName: string): (ROI | POI)[] {
    const crops = this.selectedCrops.filter((crop) => {
      const roi = this._rois.find((roi) => roi.id === crop.id);
      const labels = roi.labels.find(
        (label) => label.findingId === crop.labels[0].findingId
      ).labels;
      return !Object.keys(labels).includes(
        this.protocolLabels.find((label) => label.value === labelName)?.uuid
      );
    });

    return crops;
  }

  isLabelHover(roiInfo: ROI | POI): boolean {
    const hoverLabelUuid = this.getLabelUuid(this.hoverLabel);
    const crops = this.getCropsWithLabel(this.getLabelValue(hoverLabelUuid));

    return crops.some((crop) => crop.id === roiInfo.id);
  }

  getComplementaryColor(color: string): string {
    const { r, g, b } = this.rgbStringToRgb(color);

    const compR = 255 - r;
    const compG = 255 - g;
    const compB = 255 - b;

    return `rgb(${compR}, ${compG}, ${compB})`;
  }

  rgbStringToRgb(rgb: string) {
    const result = rgb.match(/\d+/g);
    return {
      r: parseInt(result[0], 10),
      g: parseInt(result[1], 10),
      b: parseInt(result[2], 10),
    };
  }

  removeSelectedCrop(roiInfo: ROI | POI): void {
    this.selectedCrops = this.selectedCrops.filter((crop) => crop !== roiInfo);
  }

  onSliderChange(event: MatSliderChange) {
    if (event.value !== null) {
      this.scale = event.value;
      this.applyScale();
    }
  }

  increaseValue() {
    if (this.scale < 3) {
      this.scale += 0.5;
      this.applyScale();
    }
  }

  decreaseValue() {
    if (this.scale > 1) {
      this.scale -= 0.5;
      this.applyScale();
    }
  }

  applyScale() {
    const container = this.scrollContainer.nativeElement;
    container.style.transform = `scale(${this.scale})`;
    container.style.transformOrigin = "top left";
  }

  trackByROI(index: number, roi: ROI | POI) {
    return roi.id;
  }

  cropIsIncluded(selectedCrops: (ROI | POI)[], roi: ROI | POI) {
    return selectedCrops.some((r) => r.id === roi.id);
  }
}
