import { createSelector } from "@ngrx/store";
import { AnalysisUtils } from "@telespot/sdk";
import { User } from "parse";
import * as ViewerCtxSelectors from "../+state/viewer-context.selectors";
import * as SyncSelectors from "../state/sync/sync.selectors";
import * as AnalysisSelectors from "./analysis/analysis.selectors";
import * as ProtocolSelectors from "./protocol/protocol.selectors";
import * as SampleCounterSelectors from "./sample-counters";
import {
  AnalysisLabel,
  getActiveCopiedAnalysis,
  getLabelAnalysisIds,
  labelsToAnalysisLabelFormat,
  Mode,
  POI,
  ROI,
} from "./analysis/analysis.reducer";

import {
  Context,
  getAllPossibleCounterFilters,
} from "./protocol/protocol.reducer";
import { roisSorted, selectRois } from "./analysis/analysis.selectors";
import { groupPositionTasks } from "./protocol/protocol.selectors";
import { CropInfo, GroupedMosaic } from "../services/drag-drop/drag-drop.service";

export const unlabeledText = {
  en: "Unlabeled",
  es: "Sin etiquetar",
  fr: "Non etiqueté",
  pt: "Não rotulado",
};

export const selectCurrentAnalyst = createSelector(
  ViewerCtxSelectors.analysisState,
  (history) =>
    (history?.user ?? history?.algorithm ?? User.current())?.toPointer()
);

export const isAnalystCurrentUser = createSelector(
  selectCurrentAnalyst,
  (currentAnalyst) =>
    currentAnalyst.className === User.current().className &&
    currentAnalyst.objectId === User.current().id
);

export const selectAnalysisForActiveTaskGroups = createSelector(
  ProtocolSelectors.selectTaskGroupsForActiveContext,
  AnalysisSelectors.selectAnalysis,
  (taskGroups, analysis) =>
    analysis.filter((an) =>
      taskGroups.map((tg) => tg.id).includes(an.pipelineId)
    )
);

export const selectActiveAnalysis = createSelector(
  selectAnalysisForActiveTaskGroups,
  selectCurrentAnalyst,
  ProtocolSelectors.selectContext,
  ViewerCtxSelectors.selectActiveSample,
  ViewerCtxSelectors.selectAsset,
  AnalysisSelectors.selectMode,
  (analysis, analyst, context, sample, asset, mode) => {
    let activeAnalysis = analysis.filter(
      (an) =>
        an.createdBy.className === analyst?.className &&
        an.createdBy.objectId === analyst?.objectId &&
        an.sampleId === sample?.id &&
        an.assetId === (context === Context.ASSET ? asset?.id : undefined)
    );
    if (mode === Mode.REVIEW)
      activeAnalysis = getActiveCopiedAnalysis(activeAnalysis);
    return activeAnalysis;
  }
);

export const selectActiveAssetContextAnalysis = createSelector(
  AnalysisSelectors.selectAnalysis,
  selectCurrentAnalyst,
  ViewerCtxSelectors.selectActiveSample,
  ViewerCtxSelectors.selectAsset,
  AnalysisSelectors.selectMode,
  (analysis, analyst, sample, asset, mode) => {
    let activeAnalysis = analysis.filter(
      (an) =>
        an.createdBy.className === analyst?.className &&
        an.createdBy.objectId === analyst?.objectId &&
        an.assetId === asset?.id &&
        an.isSampleAnalysis === false
    );
    if (mode === Mode.REVIEW)
      activeAnalysis = getActiveCopiedAnalysis(activeAnalysis);

    return {
      analysisRequest: {
        createdBy: analyst,
        sampleId: sample?.id,
        assetId: asset?.id,
      },
      analysisArray: activeAnalysis,
    };
  }
);

export const hasActiveAssetAnalysis = createSelector(
  selectActiveAssetContextAnalysis,
  (activeAssetAnalysis) => activeAssetAnalysis.analysisArray.length > 0
);

export const getActiveSegmentationFinding = createSelector(
  selectActiveAssetContextAnalysis,
  AnalysisSelectors.selectFindings,
  (activeAssetAnalysis, findings) =>
    findings.find(
      (f) => f.analysisId === activeAssetAnalysis.analysisArray[0]?.id
    )
);

export const hasCopyAnalysis = createSelector(
  selectActiveAssetContextAnalysis,
  AnalysisSelectors.selectAnalysis,
  (activeAssetAnalysis, allAnalysis) =>
    activeAssetAnalysis.analysisArray.length > 0 &&
    !allAnalysis.some((analysis) =>
      analysis?.id?.startsWith(
        "copy:" + activeAssetAnalysis.analysisArray[0].id
      )
    )
);

export const selectActiveAnalysisForTaskGroup = (taskGroupId: string) =>
  createSelector(selectActiveAnalysis, (analysis) =>
    analysis.find((an) => an.pipelineId === taskGroupId)
  );

export const selectFindingsForActiveAnalysis = (taskGroupId: string) =>
  createSelector(
    AnalysisSelectors.selectFindings,
    selectActiveAnalysisForTaskGroup(taskGroupId),
    (findings, analysis) =>
      findings.filter((f) => f.analysisId === analysis?.id)
  );

export const activeAnalysisIds = createSelector(
  selectActiveAssetContextAnalysis,
  (activeAnalysisInfo) =>
    activeAnalysisInfo.analysisArray.map((analysis) => analysis.id)
);
export const selectActiveAssetFindings = createSelector(
  activeAnalysisIds,
  AnalysisSelectors.selectFindings,
  (activeAnalysisIds, findings) =>
    findings.filter((f) => activeAnalysisIds.includes(f?.analysisId))
);
export const selectActiveROIS = createSelector(
  AnalysisSelectors.selectRois,
  activeAnalysisIds,
  (rois, activeAnalysisIds) =>
    rois.filter((roi) =>
      getLabelAnalysisIds([roi]).every((analysisId) =>
        activeAnalysisIds.includes(analysisId)
      )
    )
);

export const selectAssetAnalysisFromUser = (user: Parse.Pointer) =>
  createSelector(
    AnalysisSelectors.selectAnalysis,
    ViewerCtxSelectors.selectAsset,
    (analysis, asset) =>
      analysis
        .filter(
          (a) =>
            a.assetId === asset.id &&
            a.createdBy.className === user?.className &&
            a.createdBy.objectId === user?.objectId
        )
        .map((a) => a.id)
  );
export const selectAssetROIsFromUser = (user: Parse.Pointer) =>
  createSelector(
    AnalysisSelectors.selectRois,
    selectAssetAnalysisFromUser(user),
    (rois, analysisIds) =>
      rois.filter((roi) =>
        getLabelAnalysisIds([roi]).every((analysisId) =>
          analysisIds.includes(analysisId)
        )
      )
  );
export const selectROILabelCountFromActiveROIs = (labelId: string) =>
  createSelector(
    selectActiveROIS,
    ProtocolSelectors.labelsWithThresholds,
    (rois, labelsWithThresholds) => {
      const roiLabels: string[] = AnalysisUtils.flatten(
        (rois || []).map((roi) =>
          roi.labels.reduce((acc, currLabel) => {
            return [
              ...acc,
              ...Object.keys(currLabel.labels).filter(
                (l) => currLabel.labels[l] >= (labelsWithThresholds[l] ?? 0)
              ),
            ];
          }, [])
        )
      );

      return roiLabels.filter((label) => label === labelId).length;
    }
  );

export const selectROILabelCount = (labelId: string) =>
  createSelector(
    AnalysisSelectors.selectRois,
    ProtocolSelectors.labelsWithThresholds,
    (rois, labelsWithThresholds) => {
      const roiLabels: string[] = AnalysisUtils.flatten(
        (rois || []).map((roi) =>
          roi.labels.reduce((acc, currLabel) => {
            return [
              ...acc,
              ...Object.keys(currLabel.labels).filter(
                (l) => currLabel.labels[l] >= (labelsWithThresholds[l] ?? 0)
              ),
            ];
          }, [])
        )
      );

      return roiLabels.filter((label) => label === labelId).length;
    }
  );

export const selectSelectedROIS = createSelector(selectActiveROIS, (rois) =>
  rois?.filter((roi) => roi?.selected)
);

export const selectVisibleROIS = createSelector(
  selectActiveROIS,
  ProtocolSelectors.selectVisibleLabels,
  ProtocolSelectors.labelsWithThresholds,
  (rois, visibleLabels, labelsWithThresholds) =>
    rois.filter((roi) => {
      const roiLabels = roi.labels?.reduce(
        (acc, label) => ({
          ...acc,
          ...Object.keys(label.labels).reduce(
            (all, l) => ({ ...all, [l]: label.labels[l] }),
            {}
          ),
        }),
        {}
      );

      return Object.keys(roiLabels).some(
        (label) =>
          visibleLabels.includes(label) &&
          roiLabels[label] >= (labelsWithThresholds[label] ?? 0)
      );
    })
);

export const selectedLabelsWithAnalysisReq = createSelector(
  selectActiveAssetContextAnalysis,
  ProtocolSelectors.selectedLabels,
  selectActiveAssetFindings,
  (activeAnalysisInfo, labels, activeFindings) => {
    const pipelines: string[] = Array.from(
      new Set(labels.map((label) => label.pipelineId))
    );
    const analysesRequest = pipelines.map((p) => ({
      ...activeAnalysisInfo.analysisRequest,
      pipelineId: p,
    }));

    const activeAnalysis = activeAnalysisInfo.analysisArray;
    const needsNewAnalysis = !pipelines.every((id) =>
      activeAnalysis.find((a) => a.pipelineId === id)
    );

    if (needsNewAnalysis) {
      return {
        selectedLabels: labels,
        analysesRequest,
        activeAnalysis: [],
        analysisLabels: [],
        analysisCreation: true,
        findingsCreation: true,
      };
    }

    if (
      labels.some(
        (l) => !activeFindings.map((f) => f.taskId).includes(l.taskId)
      )
    ) {
      return {
        selectedLabels: labels,
        analysesRequest,
        activeAnalysis: activeAnalysisInfo.analysisArray,
        analysisLabels: [],
        analysisCreation: false,
        findingsCreation: true,
      };
    }
    return {
      analysisLabels: labelsToAnalysisLabelFormat(
        labels,
        activeAnalysisInfo.analysisArray,
        activeFindings
      ),
      activeAnalysis: activeAnalysisInfo.analysisArray,
      analysesRequest: [],
      selectedLabels: [],
      analysisCreation: false,
      findingsCreation: false,
    };
  }
);

export const selectActiveCount = createSelector(
  SampleCounterSelectors.selectSampleCounterFeature,
  ViewerCtxSelectors.analysisState,
  (state: SampleCounterSelectors.SampleCounterState, analysisState) => {
    const labelCountEntity = Object.values(state?.labelCount.entities).find(
      (labelCount) => labelCount.analysisStateId === analysisState?.id
    );
    return labelCountEntity ? labelCountEntity : undefined;
  }
);

export const labelCount = createSelector(
  AnalysisSelectors.selectMode,
  selectActiveCount,
  SampleCounterSelectors.selectActiveLabelCountReview,
  (mode, countEntity, reviewLabelCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? reviewLabelCount.labelCount
      : countEntity?.labelCount ?? [];
  }
);

export const multiLabelCount = createSelector(
  AnalysisSelectors.selectMode,
  selectActiveCount,
  SampleCounterSelectors.selectActiveLabelCountReview,
  SampleCounterSelectors.selectActiveMultiLabelReviewCount,
  (mode, countEntity, reviewLabelCount, multiLabelReviewCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? multiLabelReviewCount
      : countEntity?.multiLabelCount;
  }
);

export const singleLabelCount = createSelector(
  AnalysisSelectors.selectMode,
  selectActiveCount,
  SampleCounterSelectors.selectActiveLabelCountReview,
  SampleCounterSelectors.selectActiveSingleLabelReviewCount,
  (mode, countEntity, reviewLabelCount, singleLabelReviewCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? singleLabelReviewCount
      : countEntity?.singleLabelCount;
  }
);

export const labelsToSubstract = createSelector(
  multiLabelCount,
  singleLabelCount,
  ProtocolSelectors.disabledLabelsForCounter,
  (multiLabelDetail, singleLabelDetail, labelsToFilter) => {
    if (labelsToFilter.length < 1) return 0;
    if (labelsToFilter.length === 1) {
      const label = Object.keys(singleLabelDetail || {}).find((label) =>
        label.includes(labelsToFilter[0])
      );
      return label ? singleLabelDetail[label] : 0;
    } else {
      let roisToSubstract = 0;
      Object.keys(singleLabelDetail || {}).forEach((label) => {
        if (labelsToFilter.includes(label)) {
          roisToSubstract += singleLabelDetail[label];
        }
      });
      const allFilters = getAllPossibleCounterFilters(labelsToFilter);
      Object.keys(multiLabelDetail || {}).forEach((label) => {
        if (allFilters.includes(label)) {
          roisToSubstract += multiLabelDetail[label];
        }
      });
      return roisToSubstract;
    }
  }
);
export const totalCount = createSelector(
  AnalysisSelectors.selectMode,
  selectActiveCount,
  SampleCounterSelectors.selectActiveReviewCount,
  SampleCounterSelectors.selectActiveLabelCountReview,
  labelsToSubstract,
  (mode, countEntity, reviewCount, reviewLabelCount, substractLabels) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? (reviewCount ?? 0) - substractLabels
      : (countEntity?.totalCount ?? 0) - substractLabels;
  }
);

export const allFindingsFromCurrentUser = createSelector(
  AnalysisSelectors.selectFindings,
  selectCurrentAnalyst,
  (findings, currAnalyst) => {
    return findings.filter(
      (f) => f.createdBy.objectId === currAnalyst.objectId
    );
  }
);

export const roiLabelsFromUser = createSelector(
  AnalysisSelectors.selectRois,
  allFindingsFromCurrentUser,
  (rois, findingsFromCurrentUser) => {
    const findingIds = findingsFromCurrentUser.map((f) => f.id);

    return rois
      .flatMap((roi) => roi.labels)
      .filter((label) => findingIds.includes(label.findingId));
  }
);

export const totalPOICount = createSelector(
  roiLabelsFromUser,
  ProtocolSelectors.disabledLabelsForCounter,
  (roiLabels, labelsToFilter) => {
    return roiLabels.filter(
      (roi) => !Object.keys(roi.labels).every((l) => labelsToFilter.includes(l))
    ).length;
  }
);

export const selectPOILabelCount = (labelId: string) =>
  createSelector(
    labelCount,
    ProtocolSelectors.disabledLabelsForCounter,
    (labelCount, labelsToFilter) => {
      if (labelsToFilter.includes(labelId)) return -1;
      return labelCount.find((c) => c.labelId === labelId)?.rois ?? 0;
    }
  );

export const sampleReady = createSelector(
  ViewerCtxSelectors.refStripItemsReady,
  ViewerCtxSelectors.mosaicMode,
  ViewerCtxSelectors.selectActiveSample,
  AnalysisSelectors.selectMosaicLoading,

  (itemsReady, mosaicMode, selectedSample, mosaicLoading) => {
    const ready = mosaicMode
      ? itemsReady && mosaicLoading === false
      : itemsReady;
    return ready ? selectedSample : undefined;
  }
);

export const unlabeledRoiCount = createSelector(
  AnalysisSelectors.selectRois,
  totalCount,
  (rois, totalCount) => {
    const unlabeledCount = rois
      .flatMap((roi) => roi.labels)
      .filter((label) => Object.keys(label.labels).length === 0).length;

    return {
      count: unlabeledCount,
      percentage:
        totalCount > 0
          ? Number(((unlabeledCount / totalCount) * 100).toFixed(2))
          : 0,
    };
  }
);

export const hasUnlabeledRois = createSelector(
  unlabeledRoiCount,
  (unlabeledCount) => {
    return unlabeledCount?.count !== 0;
  }
);

export const unlabeledRois = createSelector(
  AnalysisSelectors.selectRois,
  (rois) => {
    return rois.filter((roi) =>
      roi.labels.some((label) => Object.keys(label.labels).length === 0)
    );
  }
);

export const fetchingCropsProgress = createSelector(
  ViewerCtxSelectors.numAssets,
  AnalysisSelectors.selectAssetAnalysisFetched,
  (numAssets, assetsProcessed) => {
    return numAssets === 0
      ? 0
      : assetsProcessed.length
        ? Math.floor((assetsProcessed.length / numAssets) * 100)
        : 0;
  }
);

export const findingUuidsWithoutCropsInfo = createSelector(
  AnalysisSelectors.selectRois,
  AnalysisSelectors.selectFindings,
  (rois, findings) => {
    const roisWithoutCrops = rois.filter((roi) => !roi.cropFileName);
    const findingsFiltered = [];
    roisWithoutCrops.forEach((roi) => {
      roi.labels.forEach((l) => {
        const finding = findings.find((f) => f.id === l.findingId);
        findingsFiltered.push(finding);
      });
    });
    return Array.from(new Set([...findingsFiltered]));
  }
);

export const taskTypesFromSelectedLabels = createSelector(
  ProtocolSelectors.selectedLabels,
  ProtocolSelectors.selectTaskGroupsWithROITasks,
  (selectedLabels, tasks) => {
    if (selectedLabels.length === 0) return [];

    const taskIds =
      Array.from(new Set([...selectedLabels.map((l) => l.taskId)])) ?? [];

    const taskTypesEnabled = taskIds
      .map((id) => {
        const task = tasks.find((t) => t.stepId === id);
        return task.type ?? null;
      })
      .filter((type) => type);

    return Array.from(new Set([...taskTypesEnabled]));
  }
);

export const analystToExtractCrops = createSelector(
  selectCurrentAnalyst,
  SyncSelectors.referenceAnalyst,
  (currAnalyst, refAnalyst) => {
    return refAnalyst ?? currAnalyst;
  }
);

export const findingInfoFromAnalyst = createSelector(
  AnalysisSelectors.selectFindings,
  selectCurrentAnalyst,
  (findings, analyst) =>
    findings
      .filter((f) => f.createdBy.objectId === analyst.objectId)
      .map((f) => ({ findingId: f.id, assetId: f.assetId, stepId: f.taskId }))
);

export const roisWithoutCropInfoFromUser = createSelector(
  selectRois,
  findingInfoFromAnalyst,
  (rois, findingInfo) => {
    const findingIds = findingInfo.map((info) => info.findingId);
    return rois.filter(
      (r) =>
        !r.cropFileName &&
        r.labels.some((l) => findingIds.includes(l.findingId))
    );
  }
);

export const roisWithoutCropsFilteredByLabel = (labelId: string) =>
  createSelector(
    roisWithoutCropInfoFromUser,
    SyncSelectors.nextLabelId,
    (rois, nextLabelId) => {
      const label = labelId ?? nextLabelId;
      const filteredRois = (rois || []).filter((roi) =>
        roi.labels.some((l) => Object.keys(l.labels).includes(label))
      );
      return filteredRois;
    }
  );

export const missingCropsFromSyncedFinding = createSelector(
  roisWithoutCropInfoFromUser,
  SyncSelectors.nextSyncedFinding,
  (roisWithoutCrops, nextSyncedFinding) => {
    if (!nextSyncedFinding) return false;
    return roisWithoutCrops.some((r) =>
      r.labels.some((l) => l.findingId === nextSyncedFinding.versionId)
    );
  }
);

export const nextToken = (labelId: string) =>
  createSelector(
    SyncSelectors.selectLabelTracking,
    SyncSelectors.nextLabelId,
    (labelTracking, nextLabelId) => {
      const label = labelId ?? nextLabelId;
      return labelTracking.find((l) => l.labelId === label)?.nextToken;
    }
  );

export const pendingAssets = createSelector(
  ViewerCtxSelectors.selectRefStripElements,
  AnalysisSelectors.selectAssetAnalysisFetched,
  (items, assetIds) => {
    return items
      .filter((i) => !assetIds.includes(i.assetId))
      .map((i) => i.assetId);
  }
);

export const getProtocolTasks = createSelector(groupPositionTasks, protocols => protocols.reduce((acc, protocol) => {
  return acc.concat(protocol.tasks);
}, []))

export const getGroupedMosaics = createSelector(
  roisSorted,
  getProtocolTasks,
  selectActiveCount,
  (rois, protocolTasks, sampleStats) => {
    const tempGroupedMosaics: GroupedMosaic[] = [];

    let shouldExit = false;

    for (const task of protocolTasks) {
      const categoryObj: Partial<GroupedMosaic> = {
        category: task.name, // Assigning the name of the task as the category name
        labels: {}, // Preparing an object to hold label data
      };

      for (const option of task.options) {
        // Destructure the needed properties from the option for ease of access.
        const { name: label, uuid, color } = option;

        const labelCount =
          sampleStats.labelCount.find((l) => l.labelId === uuid)?.rois ?? 0;

        // Filter and map ROIs to create an array of crop URLs for this option.
        const crops: CropInfo[] = rois
          .filter((roi: ROI | POI) =>
            roi.labels.some(
              (analysisLabel: AnalysisLabel) =>
                analysisLabel.labels && analysisLabel.labels[uuid] !== undefined // Checking if the roi has the specific uuid as a label
            )
          )
          .map((roi: ROI | POI): CropInfo => {
            const labelGroup = roi.labels.find((analysisLabel: AnalysisLabel) =>
              Object.keys(analysisLabel.labels).includes(uuid)
            );
            return {
              roiID: roi.id, // Aquí está la magia, asignando el id
              findingId: labelGroup.findingId,
              // crop: this._mosaicService.getCropURL(roi.cropFileName), // Y aquí sigue la rutina, obteniendo la URL
              crop: roi.cropFileName as any,
            };
          });

        if (crops.length > 0) {
          // If this task option has associated crops, add it to the labels.
          categoryObj.labels = {
            ...categoryObj.labels, // Include previous labels in the category
            [label]: { uuid, color, crops }, // Add new label with crops
          };
        }

        if (labelCount !== crops.length) {
          shouldExit = true;
          break;
        }
      }
      // If there are labels added to the category object, include it in the array.
      if (categoryObj.labels && Object.keys(categoryObj.labels).length > 0) {
        tempGroupedMosaics.push(categoryObj as GroupedMosaic);
      }

      if (shouldExit) break;
    }

    const cropsUnlabeled = rois
      .flatMap((roi) => [
        ...roi.labels.map((l) => ({
          ...l,
          roiId: roi.id,
          crop: roi.cropFileName, // TODO: map using service in component
        })),
      ])
      .filter((label) => Object.keys(label.labels).length === 0);

    if (cropsUnlabeled.length > 0) {
      tempGroupedMosaics.push({
        // category: unlabeledText[this.currentLang ?? "en"],
        category: unlabeledText["en"], // FIX: not included in state
        labels: {
          ["unlabeled"]: {
            color: "#F08F43",
            uuid: undefined,
            crops: [
              ...cropsUnlabeled.map((c) => ({
                roiID: c.roiId,
                findingId: c.findingId,
                crop: c.crop as any,
              })),
            ],
          },
        },
      });
    }

    return tempGroupedMosaics;
  })

export const getProtocolOptions = createSelector(getProtocolTasks, tasks => tasks.flatMap(task => task.options))
