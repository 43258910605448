import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import {
  mosaicMode,
  selectAssetAndIndex,
  selectRefStripElements,
} from "@telespot/analysis-refactor/data-access";
import { AuthService } from "@telespot/web-core";
import { Asset, Sample, TRoiSelectionType } from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import {
  MasksPluginService,
  TOsdActiveAction,
  ViewerConfig,
  ViewerConfigService,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { Viewer } from "openseadragon";
import { BehaviorSubject, Observable, Subject, combineLatest } from "rxjs";
import { filter, map, take, takeUntil } from "rxjs/operators";

import { BaseAssetViewer } from "../../directives/base-asset-viewer";

@Component({
  selector: "ts-openseadragon",
  templateUrl: "./openseadragon.component.html",
  styleUrls: ["./openseadragon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "osdViewer",
  providers: [ViewerConfigService],
})
export class OpenseadragonComponent
  extends BaseAssetViewer
  implements OnInit, OnDestroy, AfterViewInit
{
  private _src: Sample | Asset;
  @Input() set src(src) {
    if (!!src && src !== this._src) {
      this._src = src;
      this._viewerConfigService.clearCache();
      this.configure();
    }
  }
  get src(): Sample | Asset {
    return this._src;
  }
  private _destroy$ = new Subject<void>();

  private asset: Asset;

  @ViewChild("resizableBoxPortal", { read: ViewContainerRef }) container;
  @ViewChild("osdDiv", { read: ElementRef }) mainDiv: ElementRef;

  viewer: Viewer;
  public _conf: ViewerConfig;
  readonly useSequence: boolean = true;

  @ContentChild("viewerExtras", { read: TemplateRef }) extras: TemplateRef<any>;

  private _assets: Asset[];
  private _mosaicMode: boolean;

  private get assets() {
    return this._assets;
  }

  public get minZoom() {
    return this._getViewerOptions().minZoomLevel;
  }

  public get maxZoom() {
    return this._getViewerOptions().maxZoomLevel;
  }

  public get zoom() {
    return this.viewer?.viewport.getZoom();
  }

  public set zoom(level: number) {
    this.viewer?.viewport.zoomTo(Math.max(level, 0.01));
  }
  private _isFullScreen = new BehaviorSubject<boolean>(false);
  public readonly isFullScreen$ = this._isFullScreen.asObservable();

  private srcUpdate$ = new Subject<void>();

  public readonly cursorClass$: Observable<string | undefined> = combineLatest([
    this._viewerService.activeViewerMode$,
    this._viewerService.activeDrawingMode$,
  ]).pipe(
    map(([viewerMode, drawingMode]) => {
      switch (viewerMode) {
        case TOsdActiveAction.idle:
          return "cursor-move";
        case TOsdActiveAction.selecting:
          return "cursor-pointer";
        case TOsdActiveAction.drawing:
          switch (drawingMode) {
            case TRoiSelectionType.center:
              return "cursor-poi";
            case TRoiSelectionType.boundingBox:
              return "cursor-crosshair";
            default:
              return undefined;
          }
        case TOsdActiveAction.removing:
          return "cursor-crosshair";
        default:
          return undefined;
      }
    })
  );

  constructor(
    @Optional() private snackBar: MatSnackBar,
    private _logger: LoggerService,
    private _maskService: MasksPluginService,
    private _viewerConfigService: ViewerConfigService,
    private _viewerService: ViewerService,
    private _auth: AuthService,
    protected store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(mosaicMode).subscribe((mode) => {
      this._mosaicMode = mode;
    });
  }

  ngOnDestroy(): void {
    this._viewerConfigService.clearCache();
    try {
      this.viewer?.removeAllHandlers("full-screen");
    } catch (err) {
      this._logger.warn(
        `Error removing OpenSeadragon event handlers: ${err.message}`
      );
    }
    this._destroy$.next();
  }

  ngAfterViewInit() {
    if (this._mosaicMode) {
      window.addEventListener("keydown", this._osdKeyHandler);
    }

    combineLatest([this.store.select(selectAssetAndIndex), this.srcUpdate$])
      .pipe(
        filter(
          ([{ asset, index }, _]) => asset && this.asset?.id !== asset?.id
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(([{ asset, index }, _]) => {
        this._logger.debug(
          `SelectedAssetIndex[${index}]. Calling OSD.setPage()`
        );
        this.asset = asset;

        if (asset?.data?.width && asset?.data?.height) {
          this.setPage(index);
        }
      });
  }

  async configure() {
    if (!this.src) return;

    this._assets = [];
    if (this.src instanceof Sample) {
      await this.store
        .select(selectRefStripElements)
        .pipe(
          take(1),
          map((items) => {
            items.map(({ assetId, assetFile }) => {
              const asset = Asset.fromJSON(
                { className: "Asset", objectId: assetId, assetFile: assetFile },
                false
              ) as Asset;
              this._assets.push(asset);
            });
          })
        )
        .toPromise();
    } else {
      this._assets.push(this.src);
    }

    try {
      this._conf = await this._viewerConfigService.getConfig(this.assets);
    } catch (err) {
      this.snackBar?.open(`Error loading image: ${err.message}`, undefined, {
        duration: 1000,
      });
      this._logger.error(`Error loading image: ${err.message}`);
    }

    if (this._conf) {
      if (!this._conf.width) {
        this.snackBar !== null
          ? this.snackBar.open("Failed to load image sources", undefined, {
              duration: 1200,
            })
          : this._logger.error("Failed to load image sources");
      }

      const options = this._getViewerOptions();

      if (!this.viewer) {
        this.viewer = new Viewer(options);
      }

      this.srcUpdate$.next();
      this.viewer.addHandler("full-screen", (event) => {
        this._isFullScreen.next(event.fullScreen);
      });
    }
  }

  private _getViewerOptions(): OpenSeadragon.Options {
    return {
      element: this.mainDiv?.nativeElement,
      prefixUrl: "assets/icons/",
      defaultZoomLevel: this.useSequence ? 0 : 0.0004,
      minZoomLevel:
        this._conf?.minZoom * (this.useSequence ? 0.5 : 0.001) + 0.001,
      maxZoomLevel: this._conf?.maxZoom * (this.useSequence ? 1 : 0.001),
      immediateRender: false,
      springStiffness: 100,
      placeholderFillStyle: "#303030",
      timeout: 30000,
      animationTime: 1.5,
      showNavigator: false,
      navigatorSizeRatio: 0.15,
      autoHideControls: false,
      showNavigationControl: false,
      showSequenceControl: false,
      preload: true,
      sequenceMode: true,
      initialPage: 0,
      clickDistThreshold: 100,
      gestureSettingsMouse: {
        clickToZoom: false,
      },
      loadTilesWithAjax: true,
    };
  }

  private async setPage(index: number) {
    if (!this.viewer) return;
    this._logger.debug(
      `[OpenSeadragon] - page ${index}/${this.assets?.length}`
    );
    this._viewerReady.next(false);
    if (!this._src) {
      this._logger.warn(`[OpenSeadragon] - no src yet`);
    }
    if (!this.viewer) {
      this._logger.warn(`[OpenSeadragon] - no viewer yet`);
      return;
    }
    let conf;
    let tileSourceOptions;
    try {
      conf = await this._viewerConfigService.getConfig(this._assets?.[index]);

      tileSourceOptions = conf.toOSDTileConfig();
      this.viewer._cancelPendingImages();

      if (Object.keys(tileSourceOptions)?.length) {
        this._maskService.setAvailableMasks(conf.overlays);
        this.viewer.addTiledImage({
          tileSource: tileSourceOptions,
          replace: !!this.viewer.world.getItemCount(),
          index: 0,
          preload: true,
          crossOriginPolicy: "*",
          ajaxHeaders: {
            "X-Parse-Session-Token": this._auth.sessionToken,
          },
        });
      }
      this.viewer.world.addOnceHandler("add-item", (event) => {
        const tiledImage = event.item;
        tiledImage.addOnceHandler("fully-loaded-change", () => {
          this._viewerReady.next(true);
        });
      });
      if (this._mosaicMode)
        this.viewer.addHandler("canvas-key", this._osdKeyHandler);
    } catch (err) {
      this.snackBar?.open(`Error loading image: ${err.message}`, undefined, {
        duration: 2500,
      });
    }
  }

  public zoomIn() {
    const actualZoom = this.viewer.viewport.getZoom();
    const factor = 1.2;
    const maxZoom = this._getViewerOptions().maxZoomLevel;

    if (actualZoom * factor < maxZoom) {
      this.viewer?.viewport.zoomBy(factor);
    } else {
      this.zoom = maxZoom;
    }
  }

  public zoomOut() {
    const actualZoom = this.viewer.viewport.getZoom();
    const factor = 0.8;
    const minZoom = this._getViewerOptions().minZoomLevel;

    if (actualZoom * factor > minZoom) {
      this.viewer?.viewport.zoomBy(factor);
    } else {
      this.zoom = minZoom;
    }
  }

  public center() {
    this.viewer?.viewport.goHome();
  }

  public rotate(degrees: number) {
    this.viewer?.viewport.setRotation(
      this.viewer.viewport.getRotation() + degrees
    );
  }

  public toggleFullScreen(fullScreen: boolean = !this._isFullScreen.value) {
    this.viewer.setFullScreen(fullScreen);
    this._isFullScreen.next(fullScreen);
  }

  private _osdKeyHandler = (e) => {
    if (
      (e.ctrlKey && e.key === "s") ||
      (e.ctrlKey && e.key === "S") ||
      (e.metaKey && e.key === "s") ||
      (e.metaKey && e.key === "S")
    ) {
      this._viewerService.save();
      e.preventDefault();
      e.stopPropagation();
    }
  };
}
