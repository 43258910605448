import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const COLOR_PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ColorPickerComponent),
  multi: true,
};

@Component({
  selector: 'ts-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [COLOR_PICKER_VALUE_ACCESSOR],
})
export class ColorPickerComponent implements ControlValueAccessor {
  public hue: string;
  public color: string;

  onChange;

  writeValue(obj: string): void {
    this.color = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { return; }
  setDisabledState?(isDisabled: boolean): void { return; }

  change(color: string) {
    this.onChange(color);
  }
}
