<ng-container
  *ngIf="{
    tool: activeTool$ | async,
    mode: activeDrawingMode$ | async,
    segMode: activeSegmentationMode$ | async,
    selectedROIs: selectedROIs$ | async,
    selectedToolIndex: selectedToolIndex$ | async
  } as state"
>
  <!-- WEB DRAWING ANALYSIS TOOLS -->
  <ng-container *ngIf="!mobile && !mosaicMode">
    <div class="drawing-mode-selector-container">
      <ng-container *ngIf="availableTools$ | async as tools">
      <mat-tab-group
        class="auto-size no-inkbar"
        dynamicHeight
        (selectedTabChange)="toggleMode(tools[$event.index])"
        [animationDuration]="0"
        [disableRipple]="true"
        [selectedIndex]="state.selectedToolIndex"
      >
        <mat-tab
          [label]="tool"
          *ngFor="let tool of tools"
          [disabled]="
            !enableTagging && (tool === 'drawing' || tool === 'selecting')
          "
        >
          <ng-template matTabLabel>
            <i
              [matTooltip]="'tooltip.activate_' + tool + '_mode' | translate"
              [attr.data-cy]="'tool-' + tool"
              [attr.active]="tool === state.tool"
              [class.text-primary]="tool === state.tool"
              [class.ri-drag-move-2-line]="tool === 'idle'"
              [class.ri-pencil-line]="tool === 'drawing'"
              [class.ri-cursor-line]="tool === 'selecting'"
              [class.ri-stack-line]="tool === 'layers'"
              (click)="toggleMode(tool)"
            ></i>
          </ng-template>
          <ng-template matTabContent>
            <ng-container [ngSwitch]="state.tool">
              <ng-container *ngIf="enableTagging">
                <div
                  *ngSwitchCase="'drawing'"
                  class="flex-row justify-content-around"
                >
                  <button
                    *ngFor="let availableMode of availableDrawingModes$ | async"
                    class="toolbar-action auto flex-row align-items-center justify-content-center btn btn-auto"
                    [class.btn-flat-gray]="state.mode !== availableMode.mode"
                    [class.btn-flat-primary]="state.mode === availableMode.mode"
                    [class.disabled]="!availableMode.enabled"
                    (click)="toggleDrawingMode(availableMode.mode)"
                    [attr.data-cy]="'button-drawing-' + availableMode.mode"
                  >
                    <i
                      [class.ri-focus-3-line]="availableMode.mode === 'center'"
                      [class.ri-shape-line]="
                        availableMode.mode === 'boundingBox'
                      "
                    ></i>
                    {{
                      (availableMode.mode === "center"
                        ? "core.point"
                        : "core.region"
                      )
                        | translate
                        | uppercase
                    }}
                  </button>
                </div>
                <div
                  *ngSwitchCase="'selecting'"
                  class="flex-row justify-content-around"
                >
                  <ng-container *ngIf="state.selectedROIs.length">
                    <button
                      class="btn btn-danger toolbar-action flex-row align-items-center toolbar-action--danger justify-content-center"
                      (click)="removeSelectedROIs()"
                      [matTooltip]="'tooltip.remove_roi' | translate"
                      data-cy="button-remove-selected-rois"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                      {{ "core.delete" | translate | uppercase }}
                      <span
                        data-cy="selected-rois-count"
                        style="margin-left: 4px"
                        >({{ state.selectedROIs.length }})</span
                      >
                    </button>
                    <a
                      *ngIf="someAIROIs(state.selectedROIs)"
                      class="toolbar-action flex-row align-items-center text-success"
                      (click)="acceptAIRois(state.selectedROIs)"
                      [matTooltip]="'tooltip.accept_ai_suggestions' | translate"
                      data-cy="button-accept-ai-roi"
                    >
                      <i class="ri-checkbox-circle-line"></i>
                      {{ "core.accept" | translate | uppercase }} ({{
                        state.selectedROIs.length
                      }})
                    </a>
                  </ng-container>
                </div>
              </ng-container>
              <div
                *ngSwitchCase="'layers'"
                style="padding: 11px"
                tsMasksPlugin
                #masksPlugin="masksPlugin"
              >
                <div class="flex-row justify-content-around">
                  <button
                    *ngFor="let availableMode of availableSegmentationModes"
                    class="toolbar-action auto flex-row align-items-center justify-content-center btn btn-auto"
                    [class.btn-flat-gray]="state.segMode !== availableMode.mode"
                    [class.btn-flat-primary]="
                      state.segMode === availableMode.mode
                    "
                    [class.disabled]="!availableMode.enabled"
                    (click)="toggleSegmentationMode(availableMode.mode)"
                    [attr.data-cy]="'button-drawing-' + availableMode.mode"
                  >
                    <i
                      [class.ri-brush-line]="availableMode.mode === 'paint'"
                      [class.ri-eraser-fill]="availableMode.mode === 'edit'"
                    ></i>
                    {{
                      (availableMode.mode === "paint"
                        ? "core.draw"
                        : "core.erase"
                      )
                        | translate
                        | uppercase
                    }}
                  </button>
                </div>

                <!-- <div class="flex-row justify-content-between">
                  {{ 'label.show_masks' | translate }}
                  <mat-slide-toggle
                    [checked]="masksState.show"
                    (change)="masksPlugin.toggleLayers($event.checked)"
                  ></mat-slide-toggle>
                </div> -->
                <div>
                  <span>
                    {{ "label.masks_opacity" | translate }}
                    <mat-slider
                      [value]="masksPlugin.opacity$ | async"
                      (change)="masksPlugin.changeOpacity($event.value)"
                      [max]="1"
                      [thumbLabel]="true"
                      [displayWith]="getOpacity"
                      [step]="0.02"
                    ></mat-slider
                  ></span>
                  <span>
                    {{ "label.masks_brush_size" | translate }}
                    <mat-slider
                      [value]="masksPlugin.strokeWidth$ | async"
                      (change)="masksPlugin.changeStrokeWidth($event.value)"
                      [max]="200"
                      [thumbLabel]="true"
                      [step]="10"
                    ></mat-slider
                  ></span>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      </ng-container>
    </div>
  </ng-container>
  <!-- WEB MOSAIC ANALYSIS TOOLS -->
  <ng-container *ngIf="!mobile && mosaicMode">
    <div class="mosaic-mode-selector-container">
      <mat-tab-group
        class="auto-size no-inkbar"
        dynamicHeight
        (selectedTabChange)="toggleMosaicMode(availableModes[$event.index])"
        [selectedIndex]="(selectedIndex$ | async) ?? 0"
        [animationDuration]="0"
        [disableRipple]="true"
      >
        <mat-tab
          [label]="mode"
          *ngFor="let mode of availableModes"
          (click)="toggleMosaicMode(mode)"
        >
          <ng-template matTabLabel>
            <i
              [ngClass]="{
                'ri-table-view': mode === 'grid',
                'ri-rectangle-line': mode === 'filter',
                'ri-layout-column-line': mode === 'filter_column'
              }"
            ></i>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
  <!-- MOBILE ANALYSIS TOOLS -->
  <ng-container *ngIf="mobile">
    <div class="flex-row flex-align-center">
      <button
        mat-icon-button
        *ngFor="let viewerMode of availableTools$ | async"
        (click)="toggleMode(viewerMode)"
        [attr.data-cy]="'button-mode-' + viewerMode"
      >
        <i
          [class.ri-drag-move-2-line]="viewerMode === 'idle'"
          [class.ri-pencil-line]="viewerMode === 'drawing'"
          [class.ri-cursor-line]="viewerMode === 'selecting'"
          [class.text-primary]="viewerMode === state.tool"
        ></i>
      </button>

      <ng-container *ngIf="state.tool === 'drawing'">
        <span class="vd"></span>

        <button
          mat-icon-button
          [disabled]="!drawingMode.enabled"
          *ngFor="let drawingMode of availableDrawingModes$ | async"
          (click)="toggleDrawingMode(drawingMode.mode)"
          [attr.data-cy]="'button-drawing-' + drawingMode.mode"
        >
          <i
            [class.ri-shape-line]="drawingMode.mode === 'boundingBox'"
            [class.ri-focus-3-line]="drawingMode.mode === 'center'"
            [class.text-primary]="
              state.tool === 'drawing' && drawingMode.mode === state.mode
            "
          ></i>
        </button>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
