import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DurationPipeModule, PreprocessFilePipeModule } from '@telespot/shared/util';

import { OpenseadragonComponent } from './components/openseadragon/openseadragon.component';
import { OsdRoiComponent } from './components/osd-roi/osd-roi.component';
import { OsdZoomControllerComponent } from './components/osd-zoom-controller/osd-zoom-controller.component';
import { ResizableBoxComponent } from './components/resizable-box/resizable-box.component';
import { SampleReferenceStripComponent } from './components/sample-reference-strip/sample-reference-strip.component';
import { VideoViewerComponent } from './components/video-viewer/video-viewer.component';
import { OsdOverlayDirective } from './directives/osd-overlay.directive';
import { OsdRoisDirective } from './directives/osd-rois.directive';
import { VideoRoisDirective } from './directives/video-rois.directive';
import { MaskViewerComponent } from './components/mask-viewer/mask-viewer.component';

@NgModule({
  declarations: [
    VideoViewerComponent,
    SampleReferenceStripComponent,
    ResizableBoxComponent,
    OpenseadragonComponent,
    OsdRoisDirective,
    VideoRoisDirective,
    OsdOverlayDirective,
    OsdZoomControllerComponent,
    OsdRoiComponent,
    MaskViewerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DurationPipeModule,
    MatTooltipModule,
    PreprocessFilePipeModule,
    MatBadgeModule,
    MatSliderModule,
    ScrollingModule,
    MatButtonModule,
  ],
  exports: [
    SampleReferenceStripComponent,
    OpenseadragonComponent,
    OsdRoisDirective,
    VideoRoisDirective,
    ResizableBoxComponent,
    VideoViewerComponent,
    OsdOverlayDirective,
    OsdZoomControllerComponent,
    OsdRoiComponent,
    MaskViewerComponent,
  ],
  providers: [],
})
export class ViewersFeatureModule {}
